@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&display=swap');
body {
    color: #555;
    /* font-family: ' Sans', sans-serif; */
    font-family: 'Merriweather Sans',' Sans', sans-serif !important;
    font-size: 15px;
    font-weight: 400;

}
/* *{ */
    /* -webkit-user-select: none; */
    /* user-select: none; */
/* } */

html,
body {
    overflow-x: hidden;
}
a{
    text-decoration: none !important;
}
img {
    max-width: 100%
}

.d-table {
    width: 100%;
    height: 100%
}

.d-table-cell {
    vertical-align: middle
}

.ptb-100 {
    padding-top: 70px;
    padding-bottom: 70px
}

.pt-100 {
    padding-top: 70px
}

.pb-100 {
    padding-bottom: 100px
}

.pb-50 {
    padding-bottom: 50px
}

.pb-70 {
    padding-bottom: 70px
}

.pt-70 {
    padding-top: 70px
}

.ptb-50 {
    padding-top: 50px;
    padding-bottom: 50px
}

.pl-15 {
    padding-left: 15px
}

.pr-15 {
    padding-right: 15px
}

.mr-20 {
    margin-right: 15px
}

.pr-20 {
    padding-right: 20px
}

.pl-20 {
    padding-left: 20px
}

.mlr-5 {
    margin-right: 5px;
    margin-left: 5px
}

.mlrt-5 {
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #5d3f80 !important;
    font-family: ' Sans', sans-serif;
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: 700
}

p {
    margin-bottom: 15px;
    font-size: 15px;
    color: #555;
    line-height: 1.7
}

p:last-child {
    margin-bottom: 0
}

a {
    text-decoration: none
}

.owl-nav {
    margin-top: 0 !important
}

.owl-dots {
    margin-top: 0 !important
}

.form-control {
    height: 55px;
    border: 1px solid rgba(89, 90, 89, .25);
    padding-left: 15px
}

.form-control:focus {
    border: 1px solid #f81300;
    box-shadow: none
}

textarea {
    height: auto !important
}

.bg-f4f6f9 {
    background-color: #f4f6f9
}

.top-header-area {
    background-color: #F2F2F2 !important;
    /* padding-top: 5px;
    padding-bottom: 5px */
}

.top-header-area .container-fluid {
    max-width: 1520px
}

.header-left-content p {
    color: #fff;
    font-size: 13px
}

.header-right-content {
    text-align: right
}

.header-right-content .list ul {
    padding-left: 0;
    margin-bottom: 2px;
}

.header-right-content .list ul li {
    display: inline-block;
    margin-right: 8px;
    font-size: 13px;
}

.header-right-content .list ul li a {
    color: #fff;
    font-size: 13px
}

.header-right-content .list ul li:last-child {
    margin-right: 0
}

.buy-now-btn {
    right: 20px;
    z-index: 99;
    top: 50%;
    position: fixed;
    transform: translateY(-50%);
    border-radius: 30px;
    display: inline-block;
    color: #fff;
    background-color: #82b440;
    padding: 10px 20px 10px 42px;
    box-shadow: 0 1px 20px 1px #82b440;
    font-size: 13px;
    font-weight: 600
}

.buy-now-btn img {
    top: 50%;
    left: 20px;
    width: 15px;
    position: absolute;
    transform: translateY(-50%)
}

.buy-now-btn:hover {
    background-color: #f81300;
    color: #fff
}

.navbar-area {
    position: relative;
    /* padding-top: 15px;
    padding-bottom: 15px */
}

.navbar-area.is-sticky {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    position: -webkit-sticky;
    position: sticky;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    position: fixed;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.navbar-area .is-sticky {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    position: -webkit-sticky;
    position: sticky;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown !important;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.navbar-area .container-fluid {
    max-width: 1520px;
    margin: auto
}

.navbar-area .navbar-brand .white-logo {
    display: none
}

.navbar-area.nav-bg-2 .desktop-nav .navbar-nav .nav-item a {
    color: #5d3f80
}

.navbar-area.nav-bg-2 .desktop-nav .others-options .icon {
    color: #5d3f80
}

.nav-bg-1 {
    background-color: #5d3f80
}

.nav-bg-2 {
    background-color: #fff
}

.desktop-nav {
    padding-top: 0;
    padding-bottom: 0;
    z-index: 1;
}

.desktop-nav .navbar {
    transition: all ease .5s;
    z-index: 2;
    padding-right: 0;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0
}

.desktop-nav .navbar ul {
    margin-bottom: 0;
    list-style-type: none
}

.desktop-nav .navbar .navbar-nav .nav-item {
    position: relative;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0
}

.desktop-nav .navbar .navbar-nav .nav-item a {
    font-size: 13px;
    color: #fff;
    line-height: 1;
    position: relative;
    font-weight: 600;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-left: 10px;
    margin-right: 10px
}

.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle {
    padding-right: 16px
}
.secondNavbar  .navbar .navbar-nav .nav-item a{
    font-size: 13px;
}
.secondNavbar  .navbar .navbar-nav .nav-item ul.dropdown-menu li a{
    padding: 7px 10px;
}

.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle::after {
    display: none
}

.desktop-nav .navbar .navbar-nav .nav-item a.dropdown-toggle::before {
    content: "\f107";
    position: absolute;
    top: 1px;
    right: 0;
    font-family: 'FontAwesome' !important;
    line-height: 1;
    font-size: 14px
}

.desktop-nav .navbar .navbar-nav .nav-item a:hover,
.desktop-nav .navbar .navbar-nav .nav-item a:focus,
.desktop-nav .navbar .navbar-nav .nav-item a.active {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item a i {
    font-size: 18px;
    line-height: 0;
    position: relative;
    top: 4px
}

.desktop-nav .navbar .navbar-nav .nav-item:last-child a {
    margin-right: 0
}

.desktop-nav .navbar .navbar-nav .nav-item:first-child a {
    margin-left: 0
}

.desktop-nav .navbar .navbar-nav .nav-item:hover a,
.desktop-nav .navbar .navbar-nav .nav-item:focus a,
.desktop-nav .navbar .navbar-nav .nav-item.active a {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu {
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    display: block;
    left: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 80px;
    transition: all .3s ease-in-out;
    visibility: hidden;
    width: 250px;
    z-index: 99;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
    padding: 0
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
    color: #1b2336 !important;
    padding: 8px 11px;
    border-bottom: 1px dashed #ededed;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    display: block;
    position: relative;
    line-height: 20px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle::after {
    display: none
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle::before {
    content: "\ea4e";
    position: absolute;
    top: 14px;
    right: 13px;
    font-family: remixicon !important;
    line-height: 1;
    font-size: 17px
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    right: 250px;
    left: auto;
    top: 0;
    opacity: 0;
    visibility: hidden
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
    color: #1b2336;
    border-bottom: 1px dashed #ededed;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    position: relative
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.dropdown-toggle::after {
    display: none
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.dropdown-toggle::before {
    content: "\ea4e";
    position: absolute;
    top: 14px;
    right: 13px;
    font-family: remixicon !important;
    line-height: 1;
    font-size: 17px
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
    right: 250px;
    left: auto;
    top: 0;
    opacity: 0;
    visibility: hidden
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #000
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    right: -250px;
    left: auto;
    top: 0;
    opacity: 0;
    visibility: hidden
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #000
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    right: -250px;
    left: auto;
    top: 0;
    opacity: 0;
    visibility: hidden
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #000
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    right: -250px;
    left: auto;
    top: 0;
    opacity: 0;
    visibility: hidden
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #000
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
    right: -250px;
    left: auto;
    top: 0;
    opacity: 0;
    visibility: hidden
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
    color: #000
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
    color: #1b2336
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
    color: #1b2336
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -1px
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    top: -1px;
    visibility: visible
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    top: -15px;
    visibility: visible
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
    color: #f81300
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: 0
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child a {
    border-bottom: none
}

.desktop-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    top: 100%;
    opacity: 1;
    margin-top: 0px;
    transform: translateY(1);
    visibility: visible;
    display: block;
    z-index: 100000;
}
.secondNavbar .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    position: absolute;
    top: 100%;
    opacity: 1;
    margin-top: 0px;
    transform: translateY(1);
    visibility: visible;
    display: block;
    z-index: 100000;
}

.desktop-nav .navbar .others-options {
    padding-left: 40px
}

.desktop-nav .navbar .others-options .icon {
    font-size: 30px;
    color: #fff;
    cursor: pointer
}

.others-option-for-responsive {
    display: none !important
}

.others-option-for-responsive .dot-menu {
    padding: 0 10px;
    height: 30px;
    cursor: pointer;
    z-index: 999;
    position: absolute;
    right: 60px;
    top: -43px
}

.others-option-for-responsive .dot-menu .inner {
    display: flex;
    align-items: center;
    height: 30px
}

.others-option-for-responsive .dot-menu .inner .icon {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 100%;
    background-color: #f81300;
    color: #fff;
    font-size: 20px;
    text-align: center
}

.others-option-for-responsive .dot-menu:hover .inner .circle {
    background-color: #f81300
}

.others-option-for-responsive .container {
    position: relative
}

.others-option-for-responsive .container .container {
    position: absolute;
    right: -2px;
    top: 15px;
    max-width: 320px;
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    transition: all ease .5s;
    transform: scaleY(0);
    z-index: 2;
    padding-left: 15px;
    padding-right: 15px
}

.others-option-for-responsive .container .container.active {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1)
}

.others-option-for-responsive .others-options {
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    padding: 10px;
    background-color: #fff;
    text-align: center
}

.others-option-for-responsive .others-options ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
    align-items: center !important
}

.others-option-for-responsive .others-options ul li {
    display: inline-block
}

.others-option-for-responsive .others-options ul li .call-now {
    position: relative;
    padding-left: 60px;
    top: 8px;
    margin-bottom: 40px !important
}

.others-option-for-responsive .others-options ul li .call-now i {
    position: absolute;
    font-size: 40px;
    color: #f81300;
    left: 0;
    top: 3px
}

.others-option-for-responsive .others-options ul li .call-now p {
    margin-bottom: 8px
}

.others-option-for-responsive .others-options ul li .call-now a {
    color: #4e3072;
    font-weight: 500
}

.others-option-for-responsive .others-options ul li .default-btn {
    bottom: 9px
}

.mobile-responsive-nav {
    display: none
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.preloader-area {
    position: fixed;
    z-index: 999999;
    background-color: #f81300;
    width: 100%;
    height: 100%;
    text-align: center;
    left: 0;
    right: 0;
    top: 0
}

.preloader-area .spinner {
    width: 4em;
    height: 4em;
    transform: perspective(20em) rotateX(-24deg) rotateY(20deg) rotateZ(30deg);
    transform-style: preserve-3d;
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    transform: translateY(-45%);
    margin-left: auto;
    margin-right: auto
}

.preloader-area .spinner .disc {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: .3em dotted #fff
}

.preloader-area .spinner .disc:nth-child(1) {
    animation: rotate 12s linear infinite
}

.preloader-area .spinner .disc:nth-child(2) {
    animation: rotateDisc2 12s linear infinite
}

.preloader-area .spinner .disc:nth-child(3) {
    animation: rotateDisc3 12s linear infinite
}

.preloader-area .spinner .inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    animation: sphereSpin 6s linear infinite
}

.preloader-area .spinner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 2px dotted #fff;
    margin: -15px
}

@keyframes sphereSpin {
    0% {
        transform: rotateX(360deg) rotateY(0deg)
    }

    100% {
        transform: rotateX(0deg) rotateY(360deg)
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

@keyframes rotateDisc2 {
    from {
        transform: rotateX(90deg) rotateZ(0deg)
    }

    to {
        transform: rotateX(90deg) rotateZ(360deg)
    }
}

@keyframes rotateDisc3 {
    from {
        transform: rotateY(90deg) rotateZ(0deg)
    }

    to {
        transform: rotateY(90deg) rotateZ(360deg)
    }
}

.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 420px;
    height: 100%;
    transform: translate3d(0%, 0, 0)
}

.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    border: none;
    border-radius: 0
}

.modal.right .modal-content button.close {
    position: absolute;
    right: 20px;
    top: 30px;
    background-color: transparent;
    border: none;
    text-shadow: unset;
    box-shadow: unset;
    z-index: 99;
    opacity: 1
}

.modal.right .modal-content button.close i {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    background-color: #f81300;
    color: #fff;
    font-size: 20px;
    transition: all ease .5s;
    border-radius: 50%
}

.modal.right .modal-content button.close i:hover {
    background-color: #4e3072
}

.modal.right.fade .modal-dialog {
    right: -320px;
    transition: opacity .3s linear, right .3s ease-out
}

.modal.right.fade.show .modal-dialog {
    right: 0
}

.sidebarModal.modal .modal-body {
    padding: 25px
}

.sidebarModal.modal .modal-body .white-logo {
    display: none
}

.sidebarModal.modal .modal-body .sidebar-content {
    margin-top: 35px;
    margin-bottom: 40px
}

.sidebarModal.modal .modal-body .sidebar-content p {
    margin-bottom: 40px
}

.sidebarModal.modal .modal-body .sidebar-content h3 {
    font-size: 22px;
    margin-bottom: 15px
}

.sidebarModal.modal .modal-body .sidebar-content .sidebar-btn {
    margin-top: 25px
}

.sidebarModal.modal .modal-body .sidebar-contact-info {
    margin-top: 30px
}

.sidebarModal.modal .modal-body .sidebar-contact-info h3 {
    font-size: 22px;
    margin-bottom: 0
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list {
    padding-left: 0;
    margin-top: 30px;
    margin-bottom: 0
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li {
    list-style-type: none;
    font-size: 14px;
    color: #555;
    font-weight: 500;
    margin-bottom: 20px;
    position: relative;
    padding-left: 22px
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li:last-child {
    margin-bottom: 0
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li a {
    color: #555
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li a:hover {
    color: #f81300
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li i {
    position: absolute;
    left: 0;
    top: 2px;
    color: #f81300
}

.sidebarModal.modal .modal-body .sidebar-social-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 30px
}

.sidebarModal.modal .modal-body .sidebar-social-list li {
    display: inline-block;
    margin-right: 8px
}

.sidebarModal.modal .modal-body .sidebar-social-list li:last-child {
    margin-right: 0
}

.sidebarModal.modal .modal-body .sidebar-social-list li i {
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 40px;
    font-size: 16px;
    background: #f81300;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    transition: all ease .5s
}

.sidebarModal.modal .modal-body .sidebar-social-list li i:hover {
    transform: translateY(-5px);
    background-color: #4e3072;
    color: #fff
}

.sidebarModal.modal .modal-body .contact-form {
    margin-top: 30px
}

.sidebarModal.modal .modal-body .contact-form h3 {
    font-size: 22px;
    margin-bottom: 25px
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group {
    margin-bottom: 20px
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control {
    padding: 15px
}

.sidebarModal.modal .modal-body .contact-form #contactForm .default-btn {
    border: none
}

.sidebarModal.modal .modal-body .contact-form #contactForm .list-unstyled {
    color: #ee1010;
    font-size: 14px;
    margin-top: 8px
}

.sidebarModal.modal .modal-body .contact-form #contactForm #msgSubmit {
    font-size: 15px
}

.default-btn {
    background-color: #f81300;
    color: #fff;
    font-size: 16px;
    padding: 13px 35px;
    border-radius: 0;
    position: relative;
    transition: all ease .5s;
    border: 1px solid #f81300;
    z-index: 1
}

.default-btn i {
    position: relative;
    top: 3px;
    padding-left: 5px
}

.default-btn::before {
    position: absolute;
    content: "";
    background-color: #4e3072;
    height: 100%;
    width: 100%;
    border-radius: 30px;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transition: all ease .5s;
    z-index: -1
}

.default-btn:hover {
    color: #fff
}

.default-btn:hover::before {
    transform: scaleX(1)
}

.default-btn:focus {
    box-shadow: none
}

.read-more-btn {
    color: #4e3072;
    font-weight: 500;
    font-size: 15px;
    transition: all ease .5s;
    position: relative
}

.read-more-btn i {
    position: relative;
    top: 3px;
    padding-left: 7px
}

.read-more-btn:hover {
    color: #f81300
}

.read-more-btn.active {
    color: #f81300;
    letter-spacing: 0
}

.read-more-btn.white-color {
    color: #fff
}

.read-more-btn.white-color:hover {
    color: #f81300
}

.section-title {
    max-width: 550px;
    text-align: center;
    margin: -10px auto 40px;
    position: relative
}

.section-title.style2 {
    text-align: left;
    margin-left: 0;
    margin-right: auto
}

.section-title h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px
}

.section-title h2.white {
    color: #fff
}

.section-title p {
    margin-bottom: 20px
}

.section-title p:last-child {
    margin-bottom: 0
}

.section-title.white-title .top-title {
    color: #fff
}

.section-title.white-title h2 {
    color: #fff
}

.section-title.white-title p {
    color: #fff
}

.banner-area {
    /* background-color: #4e3072; */
    overflow: hidden;
    height: 60vh;
}

.banner-area .container-fluid {
    padding: 0;
    /* padding-left: 30px; */
    max-width: 1720px;
    margin-right: 0;
    margin-left: auto
}

.slider-item {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /* padding-top: 100px;
    padding-bottom: 100px; */
    /* padding-left: 60px; */
    overflow: hidden;

}

/* .slider-item.banner-bg-1 {
     background-image: url('https://ik.imagekit.io/ckjdgz4vmo/slider2.1e9404d2dd2269389091.png?updatedAt=1697187095859');
    background-image: url('./banner-1.png');
    
} */

/* .slider-item.banner-bg-2 {
     background-image: url('https://ik.imagekit.io/we26mxcya/sridevi_slider/banner-placement.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676372438241') 
    background-image: url('./banner-2.png');
    
} */


.slider-item.banner-bg-3 {
    background-image: url('https://ik.imagekit.io/we26mxcya/sridevi_slider/banner-admission.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676372438215')
}



.slider-content {
    max-width: 740px;
    margin-left: 0;
    margin-right: auto
}

.slider-content h1 {
    font-size: 45px;
    color: #fff;
    margin-bottom: 20px
}

.slider-content p {
    color: #fff;
    margin-bottom: 30px
}

.slider-content.style2 {
    background-color: #fff;
    padding: 50px
}

.slider-content.style2 h1 {
    color: #4e3072
}

.slider-content.style2 p {
    color: #555
}

.hero-slider {
    position: relative
}

.hero-slider.owl-theme .owl-dots {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -100px
}

.hero-slider.owl-theme .owl-dots .owl-dot {
    display: block
}

.hero-slider.owl-theme .owl-dots .owl-dot span {
    width: 45px;
    height: 45px;
    margin: 5px 0;
    background: 0 0;
    display: block;
    transition: .5s;
    border-radius: 50%;
    position: relative;
    color: #fff
}

.hero-slider.owl-theme .owl-dots .owl-dot span::before {
    position: absolute;
    content: "01";
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px
}

.hero-slider.owl-theme .owl-dots .owl-dot:hover span,
.hero-slider.owl-theme .owl-dots .owl-dot.active span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    position: relative
}

.hero-slider.owl-theme .owl-dots .owl-dot:hover span::after,
.hero-slider.owl-theme .owl-dots .owl-dot.active span::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 40px;
    background-color: #fff;
    left: -30px;
    top: 22px
}

.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(2) span::before {
    content: "02"
}

.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(3) span::before {
    content: "03"
}

.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(4) span::before {
    content: "04"
}

.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(5) span::before {
    content: "05"
}

.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(6) span::before {
    content: "06"
}

.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(7) span::before {
    content: "07"
}

.hero-slider.owl-theme .owl-dots .owl-dot:nth-child(8) span::before {
    content: "08"
}

.hero-slider.owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    bottom: 30px;
    right: 100px;
    bottom: 50px;
    transform: translateY(-50%);
    background-color: transparent;
    border: 1px solid #eee;
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    transition: all ease .5s;
    font-size: 24px
}

.hero-slider.owl-theme .owl-nav [class*=owl-] i {
    position: relative;
    top: -2px;
    left: -1px
}

.hero-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 30px;
}

.hero-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
    position: relative;
    right: -1px;
    left: auto;
    top: -2px
}

.hero-slider.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.hero-slider2 .slider-item {
    padding-left: 0
}

.hero-slider2.owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    bottom: 30px;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 1px solid #dfdbdb;
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    transition: all ease .5s;
    font-size: 24px;
    opacity: 0;
    transition: all ease .5s
}

.hero-slider2.owl-theme .owl-nav [class*=owl-] i {
    position: relative;
    top: -2px;
    left: -1px;
    color: #fff
}

.hero-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
    right: 100px;
    left: auto
}

.hero-slider2.owl-theme .owl-nav [class*=owl-].owl-next i {
    position: relative;
    right: -1px;
    left: auto;
    top: -2px
}

.hero-slider2.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.hero-slider2:hover.owl-theme .owl-nav [class*=owl-] {
    opacity: 1
}

.hero-slider2.style2.owl-theme .owl-nav [class*=owl-] {
    background: rgba(0, 0, 0, .15)
}

.hero-slider2.style2.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300
}

.owl-thumbs {
    text-align: right;
    position: relative;
    margin-top: -75px;
    z-index: 1
}

.owl-thumbs button {
    padding: 0;
    border: none;
    transition: all ease .5s
}

.owl-thumbs button:hover,
.owl-thumbs button.active {
    filter: blur(2px)
}

.owl-thumbs .owl-thumb-item {
    width: 200px;
    height: 150px
}

.banner-area.banner-area-five {
    height: 100%;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 1
}

.banner-area.banner-area-five::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, rgba(9, 0, 51, 0), rgb(8, 1, 77))
}

.banner-area.banner-area-five video {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    z-index: -2
}

.banner-area.banner-area-five .slider-item {
    position: relative;
    padding-left: 175px;
    overflow: hidden;
    z-index: 3
}

.single-academics-card {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px;
    transition: all ease .5s
}

.single-academics-card .academic-top-content {
    position: relative;
    padding-left: 40px;
    margin-bottom: 15px
}

.single-academics-card .academic-top-content h3 {
    font-size: 22px;
    transition: all ease .5s
}

.single-academics-card .academic-top-content h3:hover {
    color: #f81300
}

.single-academics-card .academic-top-content i {
    font-size: 30px;
    position: absolute;
    color: #f81300;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.single-academics-card:hover {
    transform: translateY(-10px)
}

.single-academics-card2 {
    background-size: cover;
    background-position: center center;
    height: 500px;
    padding: 30px;
    position: relative;
    margin-bottom: 30px
}

.single-academics-card2::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, transparent, rgba(17, 29, 94, 0.9));
    left: 0;
    top: 0
}

.single-academics-card2.bg-1 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/about-college.png)
}

.single-academics-card2.bg-2 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/admissionss.png)
}

.single-academics-card2.bg-3 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/syllabus.png)
}

.single-academics-card2 .serial {
    position: absolute;
    left: 30px;
    top: 30px
}

.single-academics-card2 .serial p {
    color: #fff;
    font-size: 18px
}

.single-academics-card2 .academic-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px
}

.single-academics-card2 .academic-content .academic-top-content {
    position: relative;
    padding-left: 30px
}

.single-academics-card2 .academic-content .academic-top-content h3 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 15px;
    transition: all ease .5s
}

.single-academics-card2 .academic-content .academic-top-content h3:hover {
    color: #f81300
}

.single-academics-card2 .academic-content .academic-top-content i {
    color: #f81300;
    font-size: 20px;
    position: absolute;
    left: 0
}

.single-academics-card2 .academic-content p {
    color: #fff;
    margin-bottom: 15px
}

.single-academics-card3 {
    text-align: center;
    padding: 25px;
    border: 1px solid #eee;
    margin-bottom: 30px;
    position: relative
}

.single-academics-card3::before {
    position: absolute;
    content: "";
    height: 100%;
    height: 100%;
    width: 0;
    top: 0;
    right: 0;
    background-color: #f81300;
    transition: all ease .5s
}

.single-academics-card3 .icon {
    margin-bottom: 20px
}

.single-academics-card3 .icon i {
    font-size: 35px;
    height: 65px;
    width: 65px;
    line-height: 72px;
    border-radius: 100%;
    border: 1px solid #f81300;
    display: inline-block;
    color: #f81300;
    position: relative;
    transition: all ease .5s
}

.single-academics-card3 h3 {
    font-size: 22px;
    margin-bottom: 15px;
    position: relative;
    transition: all ease .5s
}

.single-academics-card3 p {
    position: relative;
    transition: all ease .5s
}

.single-academics-card3:hover::before {
    width: 100%;
    border-radius: 0;
    left: 0;
    right: auto
}

.single-academics-card3:hover .icon i {
    color: #fff;
    border-color: #fff;
    transform: rotate(45deg)
}

.single-academics-card3:hover h3 {
    color: #fff
}

.single-academics-card3:hover p {
    color: #fff
}

.single-academics-card3:hover .read-more-btn {
    color: #fff
}

.serch-content {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.serch-content h3 {
    font-size: 22px;
    margin-bottom: 15px
}

.serch-content .form-group {
    position: relative
}

.serch-content .form-group .form-control {
    border: none;
    border: 1px solid transparent
}

.serch-content .form-group .form-control:focus {
    border: 1px solid #f81300
}

.serch-content .form-group .src-btn {
    position: absolute;
    right: 10px;
    top: 13px;
    font-size: 20px;
    background-color: transparent;
    border: none
}

.category-content {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.category-content h3 {
    font-size: 22px;
    margin-bottom: 15px
}

.category-content .form-control {
    border: none;
    border: 1px solid transparent
}

.category-content .form-control:focus {
    border: 1px solid #f81300
}

.location-type {
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px
}

.location-type h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.location-type .form-check .form-check-input {
    border-radius: 0
}

.location-type .form-check .form-check-input:checked {
    background-color: #f81300;
    border-color: #f81300
}

.location-type .form-check .form-check-input:focus {
    box-shadow: none
}

.program-level {
    margin-bottom: 30px
}

.program-level h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.program-level .form-check .form-check-input {
    border-radius: 0
}

.program-level .form-check .form-check-input:checked {
    background-color: #f81300;
    border-color: #f81300
}

.program-level .form-check .form-check-input:focus {
    box-shadow: none
}

.academics-details .details h2 {
    font-size: 36px;
    margin-bottom: 20px
}

.academics-details .details p {
    margin-bottom: 20px
}

.academics-details .details .details-images {
    margin-top: 10px;
    display: inline-block
}

.academics-details .details .details-images img {
    margin-bottom: 30px
}

.academics-details .regiStartion-and-fee {
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px
}

.academics-details .regiStartion-and-fee h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.academics-details .regiStartion-and-fee p {
    margin-bottom: 20px
}

.academics-details .regiStartion-and-fee .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.academics-details .regiStartion-and-fee .list ul li {
    margin-bottom: 10px;
    list-style-type: none
}

.academics-details .regiStartion-and-fee .list ul li:last-child {
    margin-bottom: 0
}

.academics-details .prepare-exam h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.academics-details .prepare-exam p {
    margin-bottom: 30px
}

.academics-details .prepare-exam .exam-card {
    margin-bottom: 30px;
    padding-left: 30px
}

.academics-details .prepare-exam .exam-card h4 {
    font-size: 20px;
    margin-bottom: 15px;
    position: relative
}

.academics-details .prepare-exam .exam-card h4::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #f81300;
    left: -30px;
    top: 5px
}

.academics-details .prepare-exam .exam-card p {
    margin-bottom: 0
}

.academics-list {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.academics-list h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.academics-list ul {
    padding-left: 0;
    margin-bottom: 0
}

.academics-list ul li {
    list-style-type: none;
    position: relative;
    margin-bottom: 10px
}

.academics-list ul li a {
    color: #555;
    transition: all ease .5s
}

.academics-list ul li i {
    position: absolute;
    right: 0;
    font-size: 22px
}

.academics-list ul li:last-child {
    margin-bottom: 0
}

.academics-list ul li:hover a {
    color: #f81300
}

.related-download {
    padding: 14px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.related-download h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.related-download ul {
    padding-left: 0;
    margin-bottom: 0;

}

.related-download ul li {
    margin-bottom: 10px;
    list-style-type: none;
    background-color: #fff;
    padding: 12px;
    padding-left: 70px;
    position: relative
}

.related-download ul li a {
    color: #555
}

.related-download ul li i {
    position: absolute;
    left: 0;
    background-color: #f81300;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 17px;
    color: #fff;
    padding: 16px;
}

.related-download ul li:last-child {
    margin-bottom: 0
}

.single-education-card {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    padding: 50px 13px;
    margin-bottom: 30px;
    z-index: 1;
    max-height: 200px;
}

.single-education-card.bg-1 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/about-college.png)
}

.single-education-card.bg-2 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/admissionss.png)
}

.single-education-card.bg-3 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/syllabus.png)
}

.single-education-card.bg-4 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/placement.png)
}
.single-education-card.bg-5 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/news.png)
}
.single-education-card.bg-6 {
    background-image: url(https://admin.swec.ac.in/assetsswec/img/discover-more/eventss.png)
}

.single-education-card .edication-content {
    text-align: center;
    position: relative
}

.single-education-card .edication-content .icon i {
    font-size: 50px;
    margin-bottom: 10px;
    color: #fff
}

.single-education-card .edication-content h3 {
    font-size: 17px;
    color: #fff;
    margin-bottom: 15px
}

.single-education-card .edication-content .read-more-btn:hover {
    color: #4e3072
}

.single-education-card::before {
    position: absolute;
    content: "";
    background: rgba(17, 29, 94, .6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1
}

.single-education-card::after {
    position: absolute;
    content: "";
    background: #f81300;
    display: inline-block;
    height: 100%;
    width: 0;
    top: 0;
    right: 0;
    z-index: -1;
    transition: all ease .5s
}

.single-education-card:hover::after {
    width: 100%;
    border-radius: 0;
    left: 0;
    right: auto
}

.campus-content {
    margin-bottom: 30px
}

.campus-content .campus-title {
    margin-bottom: 30px
}

.campus-content .campus-title h2 {
    font-size: 40px;
    margin-bottom: 15px
}

.campus-content .list {
    margin-bottom: 20px
}

.campus-content .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.campus-content .list ul li {
    position: relative;
    padding-left: 20px;
    list-style-type: none;
    margin-bottom: 10px
}

.campus-content .list ul li i {
    position: absolute;
    left: 0;
    color: #f81300
}

.campus-content.style-2 {
    padding: 50px;
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    position: relative;
    margin-left: -100px
}

.counter-card {
    margin-bottom: 30px
}

.counter-card h1 {
    font-size: 60px;
    margin-bottom: 5px;
    line-height: 1
}

.counter-card h1 .target {
    color: #f81300;
    position: relative;
    top: 4px
}

.counter-card h1 .target.heading-color {
    color: #4e3072;
    font-size: 60px;
    margin-left: -4px
}

.campus-image {
    margin-bottom: 30px
}

.estemate-form {
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    position: relative;
    margin-top: -200px;
    z-index: 1
}

.estemate-form h3 {
    font-size: 30px;
    margin-bottom: 30px
}

.estemate-form .form-group {
    margin-bottom: 25px
}

.estemate-form .form-group .form-control {
    background-color: #f4f6f9;
    border: 1px solid #f4f6f9
}

.estemate-form .form-group .form-control:focus {
    border: 1px solid #f81300
}

.estemate-form .default-btn {
    display: block;
    width: 100%
}

.single-courses-card {
    margin-bottom: 30px;
    transition: all ease .5s
}

.single-courses-card .courses-content {
    background-color: #fff;
    padding: 30px
}

.single-courses-card .courses-content .admin-profile {
    position: relative;
    padding-left: 80px;
    padding-bottom: 35px;
    margin-bottom: 20px
}

.single-courses-card .courses-content .admin-profile img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: auto
}

.single-courses-card .courses-content .admin-profile p a {
    color: #f81300;
    font-weight: 500
}

.single-courses-card .courses-content .admin-profile::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #eee;
    right: -30px;
    bottom: 10px
}

.single-courses-card .courses-content h3 {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;
    transition: all ease .5s
}

.single-courses-card .courses-content h3:hover {
    color: #f81300
}

.single-courses-card .courses-content .bottom-content ul {
    padding-left: 0;
    margin-bottom: 0
}

.single-courses-card .courses-content .bottom-content ul li {
    list-style-type: none;
    font-weight: 500
}

.single-courses-card .courses-content .bottom-content ul li a {
    color: #f81300
}

.single-courses-card .courses-content .bottom-content ul li ul li {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    padding-left: 22px
}

.single-courses-card .courses-content .bottom-content ul li ul li i {
    position: absolute;
    left: 0;
    top: 0;
    color: #f81300
}

.single-courses-card.style2 {
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.single-courses-card.style2 .courses-content .admin-profile {
    padding-bottom: 0;
    padding-left: 0
}

.single-courses-card.style2 .courses-content .admin-profile::before {
    display: none
}

.single-courses-card.style2 .courses-content .admin-profile img {
    right: 0;
    left: auto;
    top: -60px
}

.single-courses-card.style2:hover {
    transform: translateY(0)
}

.single-courses-card.style3 .list-1 {
    margin-bottom: 15px
}

.single-courses-card.style3 .list-1 ul {
    padding-left: 0;
    margin-bottom: 0
}

.single-courses-card.style3 .list-1 ul li {
    list-style-type: none
}

.single-courses-card.style3 .list-1 ul li i {
    color: #f81300;
    margin-right: 7px
}

.single-courses-card.style3 h3 {
    margin-bottom: 35px
}

.single-courses-card.style3 .admin-profile {
    padding-bottom: 20px;
    margin-bottom: 45px
}

.single-courses-card.style3 .admin-profile img {
    top: -15px
}

.single-courses-card.style3 .admin-profile::before {
    right: 0;
    bottom: -30px
}

.single-courses-card.style3 .bottom-content ul li ul li {
    margin-right: 0;
    color: #ffc107
}

.single-courses-card.style3 .bottom-content ul li ul li.blank {
    color: #555
}

.single-courses-card.style3:hover {
    transform: translateY(0)
}

.single-courses-card.style4 .courses-content {
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.single-courses-card:hover {
    transform: translateY(-10px)
}

.courses-slider.owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    bottom: 30px;
    left: -80px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #eee;
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    transition: all ease .5s;
    font-size: 24px
}

.courses-slider.owl-theme .owl-nav [class*=owl-] i {
    position: relative;
    top: -2px;
    left: -1px
}

.courses-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: -80px;
    left: auto
}

.courses-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
    position: relative;
    right: -1px;
    left: auto;
    top: -2px
}

.courses-slider.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.courses-slider.owl-theme .owl-dots {
    left: 0
}

.courses-slider.owl-theme .owl-dots .owl-dot span {
    background-color: #f81300;
    margin: 10px 10px 0;
    background-color: #dfdbdb;
    height: 18px;
    width: 18px
}

.courses-slider.owl-theme .owl-dots .owl-dot.active span {
    position: relative
}

.courses-slider.owl-theme .owl-dots .owl-dot.active span::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    background-color: #f81300;
    border-radius: 100%;
    top: 4px;
    left: 4px
}

.courses-slider.style-2.owl-theme .owl-dots {
    display: none
}

.paginations {
    text-align: center;
    margin-bottom: 30px
}

.paginations ul {
    padding-left: 0;
    margin-bottom: 0
}

.paginations ul li {
    display: inline-block;
    padding-right: 10px
}

.paginations ul li a {
    text-align: center;
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 100%;
    border: 1px solid #eee;
    display: inline-block;
    transition: all ease .5s;
    color: #4e3072;
    font-size: 18px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.paginations ul li a i {
    position: relative;
    top: 2px
}

.paginations ul li a:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.paginations ul li a.active {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.courses-details .courses-card {
    margin-bottom: 40px
}

.courses-details .courses-card h2 {
    font-size: 36px;
    margin-bottom: 25px
}

.courses-details .courses-card .img {
    margin-bottom: 50px
}

.courses-details .courses-card .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.courses-details .courses-card .list ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 30px
}

.courses-details .courses-card .list ul li .teacher {
    position: relative;
    padding-left: 70px
}

.courses-details .courses-card .list ul li .teacher img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.courses-details .courses-card .list ul li .teacher p a {
    color: #f81300
}

.courses-details .courses-card .list ul li span {
    color: #4e3072;
    font-weight: 500
}

.courses-details .courses-card .list ul li i {
    color: #f81300;
    position: relative;
    top: 2px;
    padding-right: 7px
}

.description {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-bottom: 30px
}

.description .nav-tabs {
    font-size: 18px;
    margin-bottom: 30px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    border: none
}

.description .nav-tabs .nav-link {
    color: #4e3072;
    padding-bottom: 10px;
    padding: 15px 30px;
    font-weight: 600;
    border: none;
    border-radius: 0
}

.description .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #f81300 !important;
    border-radius: 0;
    border: none;
    background-color: transparent
}

.overview .learn {
    margin-bottom: 30px
}

.overview .learn h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.overview .learn p {
    margin-bottom: 20px
}

.overview .overview-box {
    padding-left: 30px;
    margin-bottom: 30px
}

.overview .overview-box h4 {
    font-size: 18px;
    position: relative
}

.overview .overview-box h4::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #f81300;
    left: -30px;
    top: 5px
}

.curriculum {
    margin-bottom: 30px
}

.curriculum h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.curriculum p {
    margin-bottom: 20px
}

.curriculum .curriculum-list {
    padding: 30px;
    background-color: #f4f6f9
}

.curriculum .curriculum-list ul {
    padding-left: 0;
    margin-bottom: 0
}

.curriculum .curriculum-list ul li {
    list-style-type: none;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d5d0d0;
    position: relative
}

.curriculum .curriculum-list ul li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0
}

.curriculum .curriculum-list ul li span {
    position: absolute;
    right: 0;
    top: -5px;
    background-color: #f81300;
    color: #fff;
    padding: 5px 10px
}

.instructor {
    margin-bottom: 30px
}

.instructor .instructor-content .name {
    margin-bottom: 25px
}

.instructor .instructor-content .name h3 {
    font-size: 24px;
    margin-bottom: 10px
}

.instructor .instructor-content .quick-contact {
    margin-bottom: 20px
}

.instructor .instructor-content .quick-contact h4 {
    font-size: 20px;
    margin-bottom: 20px
}

.instructor .instructor-content .quick-contact ul {
    padding-left: 0;
    margin-bottom: 0
}

.instructor .instructor-content .quick-contact ul li {
    list-style-type: none
}

.instructor .instructor-content .quick-contact ul li i {
    color: #f81300;
    position: relative;
    top: 2px;
    margin-right: 7px
}

.instructor .instructor-content .quick-contact ul li a {
    color: #f81300
}

.instructor .instructor-content .social ul {
    padding-left: 0;
    margin-bottom: 0
}

.instructor .instructor-content .social ul li {
    display: inline-block;
    list-style-type: none;
    margin-right: 10px
}

.instructor .instructor-content .social ul li a {
    height: 35px;
    width: 35px;
    line-height: 35px;
    border-radius: 7px;
    text-align: center;
    font-size: 18px;
    background-color: #f81300;
    color: #fff;
    display: inline-block;
    transition: all ease .5s
}

.instructor .instructor-content .social ul li a:hover {
    background-color: #4e3072;
    color: #fff
}

.review-form h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.review-form .form-group {
    margin-bottom: 30px
}


.single-reviews-card {
    position: relative;
    padding: 30px;
    padding-left: 140px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.single-reviews-card .date {
    margin-bottom: 20px
}

.single-reviews-card .icon {
    position: absolute;
    right: 30px;
    top: 60px;
    color: #ffc107
}

.single-reviews-card h2 {
    font-size: 22px;
    margin-bottom: 10px
}

.single-reviews-card img {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%)
}

.comments {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px
}

.comments h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.comments .single-comments-box {
    padding-left: 110px;
    position: relative;
    margin-bottom: 30px
}

.comments img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.comments .reply {
    position: absolute;
    right: 0;
    background-color: #f4f6f9;
    padding: 10px 15px;
    top: 15px;
    transition: all ease .5s
}

.comments .reply a {
    color: #555;
    transition: all ease .5s
}

.comments .reply:hover {
    background-color: #f81300
}

.comments .reply:hover a {
    color: #fff
}

.comments h4 {
    font-size: 20px;
    margin-bottom: 10px
}

.comments .date {
    margin-bottom: 20px
}

.reply-area {
    margin-bottom: 30px
}

.reply-area h3 {
    font-size: 24px;
    margin-bottom: 10px
}

.reply-area p {
    margin-bottom: 20px
}

.reply-area .reply-form .form-group {
    margin-bottom: 25px
}

.reply-area .reply-form .form-group .form-control {
    background-color: #f4f6f9;
    border: 1px solid transparent
}

.reply-area .reply-form .form-group .form-control:focus {
    border-color: #f81300
}

.reply-area .reply-form .form-check {
    margin-bottom: 30px
}

.reply-area .reply-form .form-check .form-check-input {
    border-radius: 0
}

.reply-area .reply-form .form-check .form-check-input:checked {
    background-color: #f81300;
    border-color: #f81300
}

.reply-area .reply-form .form-check .form-check-input:focus {
    box-shadow: none
}

.enroll-courses {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.enroll-courses .enroll-img {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px
}

.enroll-courses .enroll-img img {
    overflow: hidden;
    transition: all ease .5s
}

.enroll-courses .enroll-img .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 1
}

.enroll-courses .enroll-img .icon a {
    font-size: 35px;
    background-color: #fff;
    color: #f81300;
    text-align: center;
    height: 60px;
    width: 60px;
    line-height: 60px;
    border-radius: 100%;
    transition: all ease .5s
}

.enroll-courses .enroll-img .icon a:hover {
    background-color: #f81300;
    color: #fff
}

.enroll-courses .enroll-img .icon::before {
    position: absolute;
    content: "";
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, .006);
    animation: ripple 4s linear infinite;
    z-index: -1
}

.enroll-courses .enroll-img .icon::after {
    position: absolute;
    content: "";
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, .004);
    animation: ripple 3s linear infinite;
    z-index: -1
}

.enroll-courses .enroll-img:hover img {
    transform: scale(1.2);
    filter: blur(3px)
}

.enroll-courses .list {
    margin-bottom: 30px
}

.enroll-courses .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.enroll-courses .list ul li {
    list-style-type: none;
    text-align: right;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e6e1e1
}

.enroll-courses .list ul li span {
    position: absolute;
    left: 0;
    color: #4e3072;
    font-weight: 600
}

.enroll-courses .list ul li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0
}

.enroll-courses .default-btn {
    display: block
}

.single-campus-card {
    margin-bottom: 30px;
    transition: all ease .5s;
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.single-campus-card .campus-content {
    padding: 30px;
    padding-bottom: 26px
}

.single-campus-card .campus-content span {
    color: #f81300;
    margin-bottom: 15px;
    display: inline-block;
    font-weight: 500
}

.single-campus-card .campus-content h3 {
    font-size: 24px;
    margin-bottom: 0;
    line-height: 1.3;
    transition: all ease .5s
}

.single-campus-card .campus-content h3:hover {
    color: #f81300
}

.single-campus-card.style2 .campus-content {
    background-color: #fff
}

.single-campus-card.style2 .campus-content span {
    position: relative;
    padding-left: 55px
}

.single-campus-card.style2 .campus-content span::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 50px;
    background-color: #f81300;
    left: 0;
    bottom: 5px
}

.single-campus-card.style2:hover {
    transform: translateY(0)
}

.single-campus-card.style-3 {
    position: relative
}

.single-campus-card.style-3 .campus-content {
    background-color: #fff;
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    transition: all ease .5s;
    transform: scaleY(0)
}

.single-campus-card.style-3:hover {
    transform: translateY(0)
}

.single-campus-card:hover {
    transform: translateY(-10px)
}

.campus-slider.owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    bottom: 30px;
    left: -80px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #dfdbdb;
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    transition: all ease .5s;
    font-size: 24px
}

.campus-slider.owl-theme .owl-nav [class*=owl-] i {
    position: relative;
    top: -2px;
    left: -1px
}

.campus-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: -80px;
    left: auto
}

.campus-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
    position: relative;
    right: -1px;
    left: auto;
    top: -2px
}

.campus-slider.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.campus-slider.owl-theme .owl-dots {
    left: 0
}

.campus-slider.owl-theme .owl-dots .owl-dot span {
    background-color: #f81300;
    margin: 10px 10px 0;
    background-color: #bbbaba;
    height: 18px;
    width: 18px
}

.campus-slider.owl-theme .owl-dots .owl-dot.active span {
    position: relative
}

.campus-slider.owl-theme .owl-dots .owl-dot.active span::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    background-color: #f81300;
    border-radius: 100%;
    top: 4px;
    left: 4px
}

.campus-slider2.owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    bottom: 30px;
    left: -80px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #dfdbdb;
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    transition: all ease .5s;
    font-size: 24px
}

.campus-slider2.owl-theme .owl-nav [class*=owl-] i {
    position: relative;
    top: -2px;
    left: -1px
}

.campus-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
    right: -80px;
    left: auto
}

.campus-slider2.owl-theme .owl-nav [class*=owl-].owl-next i {
    position: relative;
    right: -1px;
    left: auto;
    top: -2px
}

.campus-slider2.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.active.center .single-campus-card.style-3 .campus-content {
    transform: scaleY(1)
}

.admisssion-area.admission-bg {
    background-image: url(https://admin.swec.ac.in/assetsswec/images/admission/admission-3.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden
}

.admisssion-area.admission-bg::before {
    position: absolute;
    content: "";
    background: rgba(17, 29, 94, .75);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0
}

.admission-left-content {
    position: relative
}

.admission-left-content h2 {
    font-size: 40px;
    color: #fff;
    margin-bottom: 20px
}

.admission-left-content p {
    color: #fff;
    margin-bottom: 30px
}

.admission-right-content {
    position: relative;
    text-align: right
}

.admission-right-content p {
    padding-right: 100px;
    position: relative;
    color: #fff;
    top: 20px
}

.admission-right-content .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1
}

.admission-right-content .icon a {
    font-size: 35px;
    background-color: #fff;
    color: #f81300;
    text-align: center;
    height: 60px;
    width: 60px;
    display: inline-block;
    line-height: 60px;
    border-radius: 100%;
    transition: all ease .5s
}

.admission-right-content .icon a:hover {
    background-color: #f81300;
    color: #fff
}

.admission-right-content .icon::before {
    position: absolute;
    content: "";
    height: 70px;
    width: 70px;
    border-radius: 100%;
    left: -5px;
    top: -5px;
    background-color: rgba(255, 255, 255, .006);
    animation: ripple 4s linear infinite;
    z-index: -1
}

.admission-right-content .icon::after {
    position: absolute;
    content: "";
    height: 80px;
    width: 80px;
    left: -10px;
    top: -10px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, .004);
    animation: ripple 3s linear infinite;
    z-index: -1
}

@keyframes ripple {
    0% {
        transform: scale(1)
    }

    75% {
        transform: scale(1.75);
        opacity: .6
    }

    100% {
        transform: scale(2);
        opacity: 0
    }
}

.admission-content .admission-image {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 10px
}

.admission-content .admission-image img {
    overflow: hidden;
    transition: all ease .5s
}

.admission-content .admission-image .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 1
}

.admission-content .admission-image .icon a {
    font-size: 35px;
    color: #fff;
    text-align: center;
    height: 60px;
    width: 60px;
    line-height: 60px;
    border-radius: 100%;
    transition: all ease .5s
}

.admission-content .admission-image .icon a:hover {
    background-color: #f81300;
    color: #fff
}

.admission-content .admission-image .icon::before {
    position: absolute;
    content: "";
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background-color: rgba(227, 40, 69, .006);
    animation: ripple 4s linear infinite;
    z-index: -1
}

.admission-content .admission-image .icon::after {
    position: absolute;
    content: "";
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background-color: rgba(227, 40, 69, .004);
    animation: ripple 3s linear infinite;
    z-index: -1
}

.admission-content .admission-image:hover img {
    transform: scale(1.2);
    filter: blur(3px)
}

.admission-content .query p {
    margin-bottom: 30px
}

.how-to-apply h2 {
    font-size: 30px;
    margin-bottom: 20px
}

.how-to-apply p {
    margin-bottom: 18px
}

.how-to-apply .apply-list {
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee
}

.how-to-apply .apply-list ul {
    padding-left: 0;
    margin-bottom: 0
}

.how-to-apply .apply-list ul li {
    list-style-type: none;
    margin-bottom: 12px;
    position: relative;
    padding-left: 20px
}

.how-to-apply .apply-list ul li i {
    position: absolute;
    color: #f81300;
    left: 0
}

.submit {
    padding-bottom: 16px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee
}

.submit h3 {
    font-size: 30px;
    margin-bottom: 20px
}

.submit p {
    margin-bottom: 25px
}

.financial {
    margin-bottom: 30px
}

.financial h3 {
    font-size: 30px;
    margin-bottom: 20px
}

.financial p {
    margin-bottom: 25px
}



.additional-information h3 {
    font-size: 21px;
    margin-bottom: 9px
}

.additional-information .list {
    text-align: center
}

.additional-information .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.additional-information .list ul li {
    list-style-type: none;
    margin-bottom: 14px;
    padding: 10px;
    background-color: #fff;
    transition: all ease .5s;
    text-align: start;
}

.additional-information .list ul li a {
    color: #555;
    transition: all ease .5s
}

.additional-information .list ul li:hover {
    background-color: #f81300;
    color: #fff !important;
}

.additional-information .list ul li:hover a {
    color: #fff !important;
}

.single-program-card {
    background-color: #fff;
    margin-bottom: 30px
}

.single-program-card .program-content {
    padding: 30px
}

.single-program-card .program-content h3 {
    font-size: 22px;
    margin-bottom: 17px
}

.single-program-card .program-content h3 a {
    color: #4e3072;
    transition: all ease .5s
}

.single-program-card .program-content h3 a:hover {
    color: #f81300
}

.single-program-card .program-content p {
    margin-bottom: 17px
}

.single-health-care-card {
    margin-bottom: 30px;
    transition: all ease .5s
}

.single-health-care-card .health-care-content {
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    padding: 30px
}

.single-health-care-card .health-care-content h3 {
    font-size: 24px;
    margin-bottom: 15px;
    transition: all ease .5s;
    line-height: 1.3
}

.single-health-care-card .health-care-content p {
    margin-bottom: 15px
}

.single-health-care-card.style1 {
    position: relative
}

.single-health-care-card.style1 .health-care-content {
    background-color: #fff;
    max-width: 420px;
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    bottom: -70px
}

.single-health-care-card.style2 {
    position: relative;
    padding-top: 150px
}

.single-health-care-card.style2 .health-care-content {
    background-color: #fff;
    max-width: 420px;
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    top: 0
}

.single-health-care-card.style-3 {
    position: relative
}

.single-health-care-card.style-3 .health-care-content {
    background-color: #fff;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px
}

.single-health-care-card.style-3:hover {
    transform: translateX(0)
}

.single-health-care-card:hover {
    transform: translateX(-10px)
}

.single-health-care-card:hover .health-care-content h3 {
    color: #f81300
}

.more-health-care.style2 {
    text-align: right !important;
    padding-right: 200px
}

.health-care-slider.owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    bottom: 30px;
    left: -80px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #dfdbdb;
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    transition: all ease .5s;
    font-size: 24px
}

.health-care-slider.owl-theme .owl-nav [class*=owl-] i {
    position: relative;
    top: -2px;
    left: -1px
}

.health-care-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: -80px;
    left: auto
}

.health-care-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
    position: relative;
    right: -1px;
    left: auto;
    top: -2px
}

.health-care-slider.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.health-details .top-content img {
    margin-bottom: 25px
}

.health-details .top-content h2 {
    font-size: 40px;
    margin-bottom: 20px
}

.health-details .top-content p {
    margin-bottom: 25px
}

.health-details .health-care h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.health-details .health-care p {
    margin-bottom: 20px
}

.health-details .health-care-access {
    margin-bottom: 20px
}

.health-details .health-care-access h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.health-details .health-care-access p {
    margin-bottom: 20px
}

.madical-care-content .medical-card {
    margin-bottom: 30px
}

.madical-care-content .medical-card .content {
    background-color: #f81300;
    text-align: center;
    padding: 20px 0 15px
}

.madical-care-content .medical-card .content h4 {
    font-size: 22px;
    color: #fff
}

.madical-care-content .medical-care {
    margin-bottom: 30px
}

.madical-care-content .medical-care h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.madical-care-content .medical-care p {
    margin-bottom: 20px
}

.madical-care-content .medical-care .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.madical-care-content .medical-care .list ul li {
    margin-bottom: 10px;
    list-style-type: none;
    position: relative;
    padding-left: 20px
}

.madical-care-content .medical-care .list ul li::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #f81300;
    left: 0;
    top: 6px
}

.madical-care-content .medical-care .list ul li:last-child {
    margin-bottom: 10px
}

.tranding {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.tranding h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.tranding .tranding-box {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d4d3d3
}

.tranding .tranding-box .tranding-content {
    position: relative;
    padding-left: 105px;
    padding-top: 15px;
    padding-bottom: 15px
}

.tranding .tranding-box .tranding-content h4 {
    font-size: 16px;
    margin-bottom: 5px
}

.tranding .tranding-box .tranding-content h4 a {
    color: #555;
    transition: all ease .5s
}

.tranding .tranding-box .tranding-content img {
    height: 80px;
    width: 90px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.tranding .tranding-box:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0
}

.tranding .tranding-box:hover .tranding-content h4 a {
    color: #f81300
}

.single-events-card {
    margin-bottom: 30px;
    transition: all ease .5s
}

.single-events-card .events-image {
    position: relative
}

.single-events-card .events-image .date {
    background-color: #fff;
    position: absolute;
    bottom: -35px;
    left: 30px;
    padding: 15px 25px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    border-radius: 5px
}

.single-events-card .events-image .date span {
    font-size: 18px;
    color: #f81300;
    font-weight: 600;
    margin-bottom: 10px
}

.single-events-card .events-content {
    padding: 30px;
    background-color: #fff
}

.single-events-card .events-content .admin {
    text-align: right;
    margin-bottom: 20px
}

.single-events-card .events-content .admin p a {
    color: #555
}

.single-events-card .events-content .admin p a i {
    color: #f81300;
    padding-right: 7px
}

.single-events-card .events-content h3 {
    font-size: 19px;
    transition: all ease .5s;
    list-style: 1.3
}

.single-events-card:hover {
    transform: translateX(-10px)
}

.single-events-card:hover .events-content h3 {
    color: #f81300
}

.single-events-card.style2 .events-content {
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.single-events-card.style2 .events-content h3 {
    margin-bottom: 15px
}

.single-events-card.style2 .events-content .admin-and-date ul {
    padding-left: 0;
    margin-bottom: 0
}

.single-events-card.style2 .events-content .admin-and-date ul li {
    list-style-type: none
}

.single-events-card.style2 .events-content .admin-and-date ul li a {
    color: #555
}

.single-events-card.style2 .events-content .admin-and-date ul li a i {
    color: #f81300;
    margin-right: 7px
}

.single-events-card.style2:hover {
    transform: translateX(0)
}

.single-events-card.style-3 .events-content {
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.single-events-card.style-3 .events-content .date-and-read-more ul {
    padding-left: 0;
    margin-bottom: 0
}

.single-events-card.style-3 .events-content .date-and-read-more ul li {
    list-style-type: none
}

.single-events-card.style-3 .events-content .date-and-read-more ul li p i {
    color: #f81300;
    position: relative;
    top: 2px;
    margin-right: 7px
}

.single-events-card.style-4 .events-content {
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.events-slider.owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    bottom: 30px;
    left: -80px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #dfdbdb;
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    transition: all ease .5s;
    font-size: 24px
}

.events-slider.owl-theme .owl-nav [class*=owl-] i {
    position: relative;
    top: -2px;
    left: -1px
}

.events-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: -80px;
    left: auto
}

.events-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
    position: relative;
    right: -1px;
    left: auto;
    top: -2px
}

.events-slider.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.events-slider.owl-theme .owl-dots {
    left: 0
}

.events-slider.owl-theme .owl-dots .owl-dot span {
    background-color: #f81300;
    margin: 10px 10px 0;
    background-color: #dbdada;
    height: 18px;
    width: 18px
}

.events-slider.owl-theme .owl-dots .owl-dot.active span {
    position: relative
}

.events-slider.owl-theme .owl-dots .owl-dot.active span::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    background-color: #f81300;
    border-radius: 100%;
    top: 4px;
    left: 4px
}

.events-details-left-content .events-image {
    margin-bottom: 30px
}

.meetings {
    padding-bottom: 15px;
    border-bottom: 1px solid #f4f6f9;
    margin-bottom: 30px
}

.meetings h2 {
    font-size: 36px;
    margin-bottom: 20px
}

.meetings p {
    margin-bottom: 20px
}

.about-lecturer h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.about-lecturer img {
    margin-bottom: 30px
}

.about-lecturer p {
    margin-bottom: 30px
}

.about-lecturer .address {
    margin-bottom: 15px
}

.about-lecturer .address h4 {
    font-size: 20px;
    margin-bottom: 20px
}

.about-lecturer .address .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.about-lecturer .address .list ul li {
    margin-bottom: 10px;
    list-style-type: none;
    position: relative;
    padding-left: 20px
}

.about-lecturer .address .list ul li::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background-color: #f81300;
    left: 0;
    top: 7px
}

.about-lecturer .address .list ul li a {
    color: #555
}

.about-lecturer .social-content {
    margin-bottom: 30px
}

.about-lecturer .social-content ul {
    padding-left: 0;
    margin-bottom: 0
}

.about-lecturer .social-content ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px
}

.about-lecturer .social-content ul li a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 100%;
    background-color: #f4f6f9;
    color: #f81300;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    transition: all ease .5s
}

.about-lecturer .social-content ul li a:hover {
    background-color: #f81300;
    color: #fff;
    transform: translateY(-5px)
}

.next-workshop {
    margin-bottom: 30px
}

.next-workshop h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.next-workshop .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.next-workshop .list ul li {
    list-style-type: none;
    margin-bottom: 10px;
    position: relative;
    padding-left: 120px
}

.next-workshop .list ul li span {
    position: absolute;
    left: 0
}

.next-workshop .list ul li:last-child {
    margin-bottom: 0
}

.brought {
    margin-bottom: 30px
}

.brought h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.brought p {
    margin-bottom: 20px
}

.brought .default-btn {
    margin-top: 10px
}

.counter-area ul {
    padding-left: 0;
    margin-bottom: 0
}

.counter-area ul li {
    list-style-type: none
}

.single-podcasts-card {
    margin-bottom: 30px
}

.single-podcasts-card .podcast-content span {
    color: #f81300;
    display: inline-block;
    margin-bottom: 10px
}

.single-podcasts-card .podcast-content h3 {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 1.3
}

.single-podcasts-card .podcast-content p {
    margin-bottom: 20px
}

.single-podcasts-card .podcast-content .play-icon {
    transition: all ease .5s;
    padding-top: 5px;
    max-width: 170px
}

.single-podcasts-card .podcast-content .play-icon i {
    color: #fff;
    height: 45px;
    width: 45px;
    line-height: 45px;
    border-radius: 100%;
    font-size: 24px;
    background-color: #f81300;
    display: inline-block;
    text-align: center;
    transition: all ease .5s
}

.single-podcasts-card .podcast-content .play-icon span {
    position: relative;
    display: inline-block;
    bottom: 8px;
    padding-left: 7px;
    transition: all ease .5s
}

.single-podcasts-card .podcast-content .play-icon:hover {
    background-color: #f81300;
    padding-top: 5px
}

.single-podcasts-card .podcast-content .play-icon:hover span {
    color: #fff
}

.single-podcasts-card.style2 .podcasts-image {
    position: relative
}

.single-podcasts-card.style2 .podcasts-image .play-btn {
    height: 45px;
    width: 45px;
    line-height: 45px;
    border-radius: 100%;
    text-align: center;
    font-size: 24px;
    border: 1px solid #fff;
    color: #fff;
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    transition: all ease .5s
}

.single-podcasts-card.style2 .podcasts-image .play-btn:hover {
    background-color: #fff;
    color: #f81300;
    border-color: #fff
}

.single-podcasts-card.style2 .podcast-content {
    background-color: #fff;
    padding: 30px
}

.single-podcasts-card.style2 .podcast-content ul {
    padding-left: 0;
    margin-bottom: 15px
}

.single-podcasts-card.style2 .podcast-content ul li {
    list-style-type: none;
    color: #f81300
}

.single-podcasts-card.style2 .podcast-content ul li.date {
    position: relative;
    padding-left: 35px
}

.single-podcasts-card.style2 .podcast-content ul li.date::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 30px;
    background-color: #f81300;
    top: 12px;
    left: 0
}

.single-podcasts-card.style2 .podcast-content h3 {
    margin-bottom: 0
}

.single-podcasts-card.style3 .podcasts-image {
    position: relative
}

.single-podcasts-card.style3 .podcasts-image .play-btn {
    height: 45px;
    width: 45px;
    line-height: 45px;
    border-radius: 100%;
    text-align: center;
    font-size: 24px;
    border: 1px solid #fff;
    background-color: #fff;
    color: #f81300;
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 1;
    transition: all ease .5s
}

.single-podcasts-card.style3 .podcasts-image .play-btn:hover {
    background-color: #f81300;
    color: #fff
}

.single-podcasts-card.style3 .podcasts-image .episodes p {
    background-color: #fff;
    color: #f81300;
    padding: 10px 15px;
    position: absolute;
    top: 30px;
    left: 30px;
    border-radius: 7px
}

.single-podcasts-card.style3 .podcast-content {
    padding: 30px 30px 0
}

.single-podcasts-card.style3 .podcast-content .date p {
    color: #f81300;
    font-weight: 500;
    position: relative;
    padding-left: 15px
}

.single-podcasts-card.style3 .podcast-content .date p::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background-color: #f81300;
    left: 0;
    top: 7px
}

.categories {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.categories h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.categories ul {
    padding-left: 0;
    margin-bottom: 0
}

.categories ul li {
    position: relative;
    list-style-type: none;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee
}

.categories ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.categories ul li a {
    color: #555;
    transition: all ease .5s
}

.categories ul li i {
    color: #f81300;
    padding-right: 10px
}

.categories ul li:hover a {
    color: #f81300
}

.categories.style-2 {
    background-color: #fff
}

.subscribe-area {
    background-image: url(https://admin.swec.ac.in/assetsswec/images/subscribe-img.jpg);
    background-size: cover;
    padding: 30px;
    text-align: center;
    position: relative;
    margin-bottom: 30px
}

.subscribe-area::before {
    position: absolute;
    content: "";
    background: rgba(17, 29, 94, .7);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0
}

.subscribe-area .top-content {
    position: relative
}

.subscribe-area .top-content i {
    font-size: 40px;
    color: #fff
}

.subscribe-area .top-content h3 {
    font-size: 28px;
    color: #fff;
    margin-bottom: 15px
}

.subscribe-area .top-content p {
    margin-bottom: 20px;
    color: #fff
}

.subscribe-area .newsletter-form {
    position: relative
}

.subscribe-area .newsletter-form .form-control {
    margin-bottom: 30px
}

.subscribe-area .newsletter-form .default-btn {
    width: 100%
}

.subscribe-area .newsletter-form .validation-danger {
    color: #f81300;
    margin-top: 5px
}

.single-stories-card {
    margin-bottom: 30px
}

.single-stories-card .stories-content {
    padding: 30px 30px 20px;
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.single-stories-card .stories-content h3 {
    font-size: 22px
}

.single-stories-card iframe {
    height: 234px;
    width: 100%
}

.single-news-card {
    margin-bottom: 30px;
    transition: all ease .5s;
    background-color: #fff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.single-news-card .news-img {
    overflow: hidden
}

.single-news-card .news-img img {
    transition: all ease .5s
}

.single-news-card .news-content {
    padding: 30px
}

.single-news-card .news-content .list {
    margin-bottom: 15px
}

.single-news-card .news-content .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.single-news-card .news-content .list ul li {
    display: inline-block;
    margin-right: 15px
}

.single-news-card .news-content .list ul li i {
    padding-right: 7px;
    color: #f81300
}

.single-news-card .news-content .list ul li a {
    color: #555
}

.single-news-card .news-content h3 {
    font-size: 22px;
    margin-bottom: 15px;
    transition: all ease .5s;
    line-height: 1.3
}

.single-news-card:hover .news-img img {
    transform: scale(1.1)
}

.single-news-card:hover .news-content h3 {
    color: #f81300
}

.single-news-card.style2 .news-content {
    background-color: #fff
}

.single-news-card.style3 {
    background-color: #fff
}

.single-news-card.style3 .news-content {
    background-color: #fff
}

.news-slider.owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    bottom: 30px;
    left: -80px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #dfdbdb;
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    transition: all ease .5s;
    font-size: 24px
}

.news-slider.owl-theme .owl-nav [class*=owl-] i {
    position: relative;
    top: -2px;
    left: -1px
}

.news-slider.owl-theme .owl-nav [class*=owl-].owl-next {
    right: -80px;
    left: auto
}

.news-slider.owl-theme .owl-nav [class*=owl-].owl-next i {
    position: relative;
    right: -1px;
    left: auto;
    top: -2px
}

.news-slider.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: #f81300;
    color: #fff;
    border: 1px solid #f81300
}

.news-slider.owl-theme .owl-dots {
    left: 0
}

.news-slider.owl-theme .owl-dots .owl-dot span {
    background-color: #f81300;
    margin: 0 15px;
    background-color: #ded6d6;
    height: 18px;
    width: 18px
}

.news-slider.owl-theme .owl-dots .owl-dot.active span {
    position: relative
}

.news-slider.owl-theme .owl-dots .owl-dot.active span::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    background-color: #f81300;
    border-radius: 100%;
    top: 4px;
    left: 4px
}

.latest-news-simple-card {
    background-image: url(https://admin.swec.ac.in/assetsswec/images/news/news-5.jpg);
    background-size: cover;
    background-position: center center;
    height: 450px;
    width: 100%;
    position: relative;
    margin-bottom: 30px
}

.latest-news-simple-card::before {
    position: absolute;
    content: "";
    background: rgba(17, 29, 94, .7);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0
}

.latest-news-simple-card .news-content {
    padding: 30px;
    position: absolute;
    left: 0;
    bottom: 0
}

.latest-news-simple-card .news-content .list {
    margin-bottom: 15px
}

.latest-news-simple-card .news-content .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.latest-news-simple-card .news-content .list ul li {
    display: inline-block;
    margin-right: 15px;
    color: #fff
}

.latest-news-simple-card .news-content .list ul li i {
    padding-right: 7px;
    color: #f81300
}

.latest-news-simple-card .news-content .list ul li a {
    color: #fff
}

.latest-news-simple-card .news-content h3 {
    font-size: 36px;
    margin-bottom: 15px;
    color: #fff
}

.latest-news-card-area h3 {
    font-size: 24px;
    margin-bottom: 20px
}

.category-list {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.category-list h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.category-list ul {
    padding-left: 0;
    margin-bottom: 0
}

.category-list ul li {
    list-style-type: none;
    position: relative;
    margin-bottom: 10px
}

.category-list ul li a {
    color: #555;
    transition: all ease .5s
}

.category-list ul li i {
    position: absolute;
    right: 0;
    font-size: 22px
}

.category-list ul li:last-child {
    margin-bottom: 0
}

.category-list ul li:hover a {
    color: #f81300
}

.related-post-area {
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.related-post-area h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.related-post-area .related-post-box {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d4d3d3
}

.related-post-area .related-post-box .related-post-content {
    position: relative;
    padding-left: 105px;
    padding-top: 10px;
    padding-bottom: 10px
}

.related-post-area .related-post-box .related-post-content h4 {
    font-size: 16px;
    margin-bottom: 10px
}

.related-post-area .related-post-box .related-post-content h4 a {
    color: #555;
    transition: all ease .5s;
    transition: all ease .5s
}

.related-post-area .related-post-box .related-post-content h4:hover a {
    color: #f81300
}

.related-post-area .related-post-box .related-post-content img {
    height: 80px;
    width: 90px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.related-post-area .related-post-box .related-post-content p i {
    color: #f81300;
    position: relative;
    top: 3px;
    margin-right: 5px
}

.related-post-area .related-post-box:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0
}

.related-post-area .related-post-box:hover .tranding-content h4 a {
    color: #f81300
}

.news-details .news-simple-card img {
    margin-bottom: 20px
}

.news-details .news-simple-card .list {
    margin-bottom: 15px
}

.news-details .news-simple-card .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.news-details .news-simple-card .list ul li {
    display: inline-block;
    margin-right: 15px
}

.news-details .news-simple-card .list ul li i {
    padding-right: 7px;
    color: #f81300
}

.news-details .news-simple-card .list ul li a {
    color: #555
}

.news-details .news-simple-card h2 {
    font-size: 36px;
    margin-bottom: 20px
}

.news-details .news-simple-card p {
    margin-bottom: 20px
}

.news-details .theory .theory-box {
    padding-left: 30px;
    margin-bottom: 30px
}

.news-details .theory .theory-box h4 {
    font-size: 18px;
    position: relative
}

.news-details .theory .theory-box h4::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #f81300;
    left: -30px;
    top: 5px
}

.news-details .blog-img-and-content {
    margin-bottom: 30px
}

.news-details .blog-img-and-content img {
    margin-bottom: 30px
}

.tags-and-share {
    padding-bottom: 25px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px
}

.tags-and-share .tags ul {
    padding-left: 0;
    margin-bottom: 0
}

.tags-and-share .tags ul li {
    margin-right: 10px;
    display: inline-block
}

.tags-and-share .tags ul li span {
    font-size: 18px;
    font-weight: 500;
    color: #4e3072;
    margin-right: 5px
}

.tags-and-share .tags ul li a {
    color: #555;
    transition: all ease .5s;
    position: relative
}

.tags-and-share .tags ul li a:hover {
    color: #f81300
}

.tags-and-share .tags ul li a::before {
    position: absolute;
    content: ",";
    right: -4px
}

.tags-and-share .tags ul li:last-child a::before {
    display: none
}

.tags-and-share .share {
    text-align: right
}

.tags-and-share .share ul {
    padding-left: 0;
    margin-bottom: 0
}

.tags-and-share .share ul li {
    display: inline-block
}

.tags-and-share .share ul li span {
    font-size: 18px;
    font-weight: 500;
    color: #4e3072;
    margin-right: 15px
}

.tags-and-share .share ul li a {
    height: 35px;
    width: 35px;
    line-height: 38px;
    border-radius: 100%;
    background-color: #f4f6f9;
    color: #f81300;
    display: inline-block;
    text-align: center;
    transition: all ease .5s
}

.tags-and-share .share ul li a:hover {
    background-color: #f81300;
    color: #fff
}

.footer-area {
    background-color: #4e3072;
    position: relative;
    z-index: 1
}

.footer-area .shape {
    position: absolute;
    top: 50px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    left: 0;
    right: 0;
    z-index: -1
}

.footer-logo-area {
    margin-bottom: 30px
}

.footer-logo-area img {
    margin-bottom: 20px
}

.footer-logo-area p {
    margin-bottom: 25px;
    color: #fff
}

.footer-logo-area .contact-list ul {
    padding-left: 0;
    margin-bottom: 0
}

.footer-logo-area .contact-list ul li {
    list-style-type: none;
    margin-bottom: 10px
}

.footer-logo-area .contact-list ul li:last-child {
    margin-bottom: 0
}

.footer-logo-area .contact-list ul li a {
    color: #fff
}

.footer-widjet {
    margin-bottom: 30px
}

.footer-widjet h3 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 30px
}

.footer-widjet .list ul {
    padding-left: 0;
    margin-bottom: 0
}

.footer-widjet .list ul li {
    list-style-type: none;
    margin-bottom: 10px
}

.footer-widjet .list ul li:last-child {
    margin-bottom: 0
}

.footer-widjet .list ul li a {
    color: #fff;
    transition: all ease .5s
}

.footer-widjet .list ul li a:hover {
    color: #f81300
}

.copyright-area {
    background-color: #F2F2F2;
}

.copyright {
    padding: 5px 0
}

.copyright .copy {
    text-align: right
}

.copyright .copy p {
    color: #fff;
}

.copyright .copy p a {
    color: #fff;
}

.copyright .social-content ul {
    padding-left: 0;
    margin-bottom: 0
}

.copyright .social-content ul li {
    display: inline-block;
    margin-right: 10px
}

.copyright .social-content ul li:last-child {
    margin-right: 0
}

.copyright .social-content ul li span {
    color: #fff
}

.copyright .social-content ul li a {
    color: #cacaca;
    transition: all ease .5s
}

.copyright .social-content ul li a:hover {
    color: #f81300
}

.page-banner-area {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 150px;
    padding-bottom: 150px
}

.page-banner-area::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(17, 29, 94, .8)
}

.page-banner-area.bg-1 {
    background-image: url(https://admin.swec.ac.in/assetsswec/images/page-banner/page-bnner-1.jpg)
}

.page-banner-area.bg-2 {
    background-image: url(https://admin.swec.ac.in/assetsswec/images/page-banner/page-bnner-2.jpg)
}

.page-banner-area .container-fluid {
    max-width: 1520px;
    margin: auto
}

.page-banner-content {
    position: relative
}

.page-banner-content h1 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #fff
}

.page-banner-content ul {
    padding-left: 0;
    margin-bottom: 0
}

.page-banner-content ul li {
    display: inline-block;
    position: relative;
    padding-right: 18px;
    font-size: 16px;
    font-weight: 500;
    color: #fff
}

.page-banner-content ul li a {
    text-decoration: none;
    color: #fff
}

.page-banner-content ul li::before {
    position: absolute;
    content: "/";
    right: 2px;
    top: 0
}

.page-banner-content ul li:last-child {
    color: #f81300;
    padding-right: 0
}

.page-banner-content ul li:last-child::before {
    display: none
}

.student-life-card {
    margin-bottom: 30px;
    position: relative
}

.student-life-card .tags {
    background-color: #f81300;
    padding: 10px 15px;
    position: absolute;
    left: 20px;
    bottom: 20px
}

.student-life-card .tags p {
    color: #fff
}

.alumni-left-content h3 {
    font-size: 30px;
    margin-bottom: 20px
}

.single-alumni-events-card {
    padding: 10px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    margin-bottom: 30px;
    transition: all ease .5s
}

.single-alumni-events-card .alumni-img {
    position: relative
}

.single-alumni-events-card .alumni-img img {
    width: 100%
}

.single-alumni-events-card .alumni-img .date {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f81300;
    padding: 8px 12px
}

.single-alumni-events-card .alumni-img .date p {
    color: #fff;
    font-size: 13px
}

.single-alumni-events-card .alumni-content .time {
    margin-bottom: 15px
}

.single-alumni-events-card .alumni-content .time p i {
    color: #f81300;
    position: relative;
    top: 2px;
    margin-right: 5px
}

.single-alumni-events-card .alumni-content h3 {
    font-size: 22px;
    margin-bottom: 15px
}

.single-alumni-events-card .alumni-content .location p i {
    color: #f81300;
    position: relative;
    top: 2px;
    margin-right: 5px
}

.single-alumni-events-card:hover {
    transform: translateY(-10px)
}

.alumni-right-content h3 {
    font-size: 30px;
    margin-bottom: 20px
}

.single-alumoni-updates-card {
    padding: 10px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    margin-bottom: 30px;
    transition: all ease .5s
}

.single-alumoni-updates-card .update-image img {
    width: 100%
}

.single-alumoni-updates-card .updates-content .date {
    margin-bottom: 10px
}

.single-alumoni-updates-card .updates-content h3 {
    font-size: 22px;
    margin-bottom: 20px
}

.single-alumoni-updates-card:hover {
    transform: translateY(-10px)
}

.interview-content {
    background-image: url(https://admin.swec.ac.in/assetsswec/images/alumni/alumni-7.jpg);
    background-size: cover;
    background-position: center center
}

.interview-content .interview {
    max-width: 465px;
    margin-right: 20px;
    margin-left: auto
}

.interview-content .interview span {
    margin-bottom: 10px;
    display: inline-block
}

.interview-content .interview h2 {
    font-size: 30px;
    margin-bottom: 20px
}

.interview-content .interview p {
    margin-bottom: 30px
}

.contacts-form {
    padding: 40px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    margin-bottom: 30px
}

.contacts-form h3 {
    font-size: 30px;
    margin-bottom: 20px
}

.contacts-form .form-group {
    margin-bottom: 25px
}

.contacts-form .form-group label {
    margin-bottom: 7px
}

.contacts-form .form-group .form-control {
    background-color: #f4f6f9;
    border: 1px solid transparent
}

.contacts-form .form-group .form-control:focus {
    border: 1px solid #f81300
}

.contacts-form .list-unstyled {
    color: #f81300;
    margin-top: 7px
}

.contacts-form .text-danger {
    color: #f81300;
    font-size: 16px;
    margin-top: 7px
}

.contacts-form .form-check .form-check-input {
    border-radius: 0
}

.contacts-form .form-check .form-check-input:checked {
    background-color: #f81300;
    border-color: #f81300
}

.contacts-form .form-check .form-check-input:focus {
    box-shadow: none
}

.contacts-form .default-btn {
    width: 100%
}

.contact-and-address h2 {
    font-size: 30px;
    margin-bottom: 20px
}

.contact-and-address p {
    margin-bottom: 20px
}

.contact-and-address .contact-and-address-content {
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    padding-bottom: 10px
}

.contact-and-address .contact-and-address-content .contact-card {
    text-align: start;
    padding: 30px;
    background-color: #f4f6f9;
    margin-bottom: 30px
}

.contact-and-address .contact-and-address-content .contact-card .icon {
    font-size: 35px;
    height: 65px;
    width: 65px;
    line-height: 65px;
    border: 1px solid #f81300;
    color: #f81300;
    /* text-align: center; */
    border-radius: 100%;
    /* margin: auto; */
    margin-bottom: 15px;
    display: flex !important;
    justify-content: center;
    align-items: center;

}

.contact-and-address .contact-and-address-content .contact-card h4 {
    font-size: 22px;
    margin-bottom: 15px
}

.contact-and-address .contact-and-address-content .contact-card p {
    margin-bottom: 10px
}

.contact-and-address .contact-and-address-content .contact-card p a {
    color: #555;
    transition: all ease .5s
}

.contact-and-address .contact-and-address-content .contact-card p a:hover {
    color: #f81300
}

.contact-and-address .social-media {
    margin-bottom: 30px
}

.contact-and-address .social-media h3 {
    font-size: 24px;
    margin-bottom: 15px
}

.contact-and-address .social-media p {
    margin-bottom: 25px
}

.contact-and-address .social-media ul {
    padding-left: 0;
    margin-bottom: 0
}

.contact-and-address .social-media ul li {
    margin-right: 10px;
    list-style-type: none;
    display: inline-block
}

.contact-and-address .social-media ul li a {
    height: 35px;
    width: 35px;
    line-height: 37px;
    border-radius: 100%;
    text-align: center;
    display: inline-block;
    background-color: #eee;
    color: #f81300;
    transition: all ease .5s;
    align-items: center;
    display: flex;
    justify-content: center;
}

.contact-and-address .social-media ul li a:hover {
    background-color: #f81300;
    color: #fff;
    transform: translateY(-5px)
}

.go-top {
    position: fixed;
    cursor: pointer;
    top: 87%;
    right: -10%;
    background-color: #f81300;
    z-index: 4;
    width: 50px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 100%;
    opacity: 0;
    visibility: hidden;
    transition: .9s
}

.go-top i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #fff;
    transition: .5s;
    font-size: 26px
}

.go-top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 60%
}

.go-top{
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.go-top::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    z-index: -1;
    background-color: #4e3072 !important;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
    }

.go-top:hover {
    color: #fff;
    background: #4e3072
}

.go-top:hover::before {
    opacity: 1;
    visibility: visible
}

.go-top:hover i {
    color: #fff
}

.go-top:hover i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden
}

.go-top:hover i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%
}

.go-top:focus {
    color: #fff
}

.go-top:focus::before {
    opacity: 1;
    visibility: visible
}

.go-top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden
}

.go-top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%
}

.go-top.active {
    transform: translateY(-95%);
    opacity: 1;
    visibility: visible;
    right: 3%;
    top: 93%
}

.faq-title h2 {
    font-size: 36px;
    margin-bottom: 20px
}

.faq-title p {
    margin-bottom: 30px
}

.accordion .accordion-item {
    margin-bottom: 20px;
    border: 1px solid rgba(227, 40, 69, .4);
    border-radius: 10px
}

.accordion .accordion-item:last-child {
    margin-bottom: 0
}

.accordion .accordion-item .accordion-button {
    border: none;
    background-color: transparent;
    color: #4e3072;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0px;
}

.accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
    outline: none
}

.accordion .accordion-item .accordion-button:not(.collapsed) {
    box-shadow: unset
}

.accordion .accordion-item .accordion-button::after {
    background-image: unset;
    content: "";
    font-size: 20px;
    color: #4e3072;
    text-align: center;
    content: "\f078";
    font-family: 'Font Awesome 6 Free';
    position: relative;
    top: -2px
}

.accordion .accordion-item .accordion-button:not(.collapsed)::after {
    background-image: unset;
    text-align: center;
    content: "\f077";
    font-family: 'Font Awesome 6 Free';
    position: relative;
    top: -2px
}

.accordion .accordion-item .accordion-body {
    padding-top: 0;
    font-size: 15px
}

.login {
    max-width: 860px;
    margin: auto;
    padding: 50px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    margin-bottom: 30px
}

.login h3 {
    font-size: 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    position: relative
}

.login h3::before {
    position: absolute;
    content: "";
    height: 2px;
    width: 70px;
    background-color: #f81300;
    top: 43px
}

.login .form-group {
    margin-bottom: 20px
}

.login .form-check {
    margin-bottom: 20px
}

.login .form-check .form-check-input:checked {
    background-color: #f81300
}

.login .form-check .form-check-input:focus {
    box-shadow: none
}

.login .default-btn {
    padding: 15px 40px;
    margin-bottom: 15px
}

.login a {
    display: inherit;
    color: #555;
    font-weight: 500
}

.register {
    max-width: 860px;
    margin: auto;
    padding: 50px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    margin-bottom: 30px
}

.register h3 {
    font-size: 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    position: relative
}

.register h3::before {
    position: absolute;
    content: "";
    height: 2px;
    width: 70px;
    background-color: #f81300;
    top: 43px
}

.register .form-group {
    margin-bottom: 20px
}

.register .default-btn {
    padding: 15px 40px;
    margin-top: 10px
}

.password {
    max-width: 650px;
    margin: auto;
    padding: 50px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.password h3 {
    font-size: 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    position: relative
}

.password h3::before {
    position: absolute;
    content: "";
    height: 2px;
    width: 70px;
    background-color: #f81300;
    top: 43px
}

.password .form-group {
    margin-bottom: 20px
}

.password .default-btn {
    width: 100%
}

.privacy-content {
    margin-bottom: 10px
}

.privacy-content h2 {
    font-size: 36px;
    margin-bottom: 10px
}

.privacy-content h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px
}

.privacy-content p {
    margin-bottom: 25px
}

.privacy-content .list ul {
    margin-bottom: 20px;
    padding-left: 0
}

.privacy-content .list ul li {
    font-weight: 600;
    color: #4e3072;
    position: relative;
    padding-left: 20px;
    list-style-type: none;
    padding-bottom: 15px
}

.privacy-content .list ul li i {
    position: absolute;
    left: 0;
    color: #f81300
}

.condition-content {
    margin-bottom: 10px
}

.condition-content h2 {
    font-size: 36px;
    margin-bottom: 30px
}

.condition-content h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px
}

.condition-content p {
    margin-bottom: 25px
}

.condition-content .list ul {
    margin-bottom: 20px;
    padding-left: 0
}

.condition-content .list ul li {
    font-weight: 600;
    color: #4e3072;
    position: relative;
    padding-left: 20px;
    list-style-type: none;
    padding-bottom: 15px
}

.condition-content .list ul li i {
    position: absolute;
    left: 0;
    color: #f81300
}

.error-area {
    text-align: center
}

.error-area .top-content {
    margin-bottom: 10px
}

.error-area .top-content ul {
    padding-left: 0;
    margin-bottom: 0
}

.error-area .top-content ul li {
    font-size: 200px;
    font-weight: 700;
    display: inline-block;
    line-height: 1;
    margin-right: 30px;
    color: #f81300;
    font-family: "source serif pro", serif
}

.error-area .top-content ul li:first-child {
    color: #4e3072
}

.error-area .top-content ul li:last-child {
    color: #555
}

.error-area h2 {
    font-size: 36px;
    margin-bottom: 20px
}

.error-area p {
    margin-bottom: 30px
}

.coming-soon-area {
    height: 100vh;
    vertical-align: middle;
    position: relative
}

.coming-soon-area::before {
    position: absolute;
    content: "";
    background: #0e0e0e;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0
}

.coming-soon-area .coming-soon {
    text-align: center;
    max-width: 720px;
    margin: auto;
    background-color: #000;
    padding: 40px;
    border-radius: 40px 10px 40px 10px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    z-index: 1;
    position: relative
}

.coming-soon-area .coming-soon img {
    margin-bottom: 20px
}

.coming-soon-area .coming-soon h1 {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #fff
}

.coming-soon-area .coming-soon .list ul {
    margin-bottom: 0;
    padding-left: 0
}

.coming-soon-area .coming-soon .list ul li {
    margin: 0 20px;
    font-size: 36px;
    font-weight: 700;
    height: 100px;
    width: 100px;
    border: 6px double #f81300;
    border-radius: 50px 0 0 0;
    margin-bottom: 30px;
    color: #fff
}

.coming-soon-area .coming-soon .list ul li span {
    font-size: 15px;
    font-weight: 500;
    color: #f81300
}

.coming-soon-area .coming-soon .newsletter-form {
    position: relative
}

.coming-soon-area .coming-soon .newsletter-form .form-control {
    border-radius: 30px;
    height: 53px;
    background-color: #0e0e0e;
    color: #fff
}

.coming-soon-area .coming-soon .newsletter-form .form-control::-moz-placeholder {
    color: #fff
}

.coming-soon-area .coming-soon .newsletter-form .form-control::placeholder {
    color: #fff
}

.coming-soon-area .coming-soon .newsletter-form .default-btn {
    position: absolute;
    right: 0;
    top: 0
}

.coming-soon-area .coming-soon .newsletter-form .validation-danger {
    color: #e60808;
    padding-top: 7px
}

.secondNavbar {
    background-color: #5d3f80;
    /* padding-top: -10px;
    padding-bottom: -10px; */
    /* position: relative; */
    height: 35px;
    align-items: center;
    /* display: flex !important; */
    justify-content: center;
}

.secondNavbar .navbar-nav .nav-item a {
    color: #fff !important;
}

.secondNavbar.navbar .navbar-nav .nav-item:first-child a {
    margin-left: 0;
}

.secondNavbar .desktop-nav .navbar .navbar-nav .nav-item {
    position: relative;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 0;
    padding-left: 0;
}

.news-heading {
    background: #f81300;
    color: #fff;
    text-align: center;
    padding: 4px 12px;
    margin-bottom: 0;
    position: relative;
    font-size: 12px;
    width: 100px;
}

marquee a {
    font-size: 13px;
    color: #ffffff !important;
    padding-top: 10px;

}

marquee:host {
    display: block !important;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
}

.secondNavbar {
    position: relative;
    z-index: inherit;
    /* padding-top: 15px;
    padding-bottom: 15px */
}

.secondNavbar.is-sticky {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    position: -webkit-sticky;
    position: sticky;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown !important;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
}

.secondNavbar .container-fluid {
    max-width: 1520px;
    margin: auto
}

.secondNavbar .navbar-brand .white-logo {
    display: none
}

.secondNavbar .nav-bg-2 .desktop-nav .navbar-nav .nav-item a {
    color: #4e3072
}

.secondNavbar .nav-bg-2 .desktop-nav .others-options .icon {
    color: #4e3072
}

.nirf a {
    background: #c942ac;
    padding: 13px 10px 11px !important;
    font-size: 11px;
    margin-right: -12px;
}

.eamcetCode a {
    background: #3eb7e9;
    padding: 12px 10px 11px !important;
    font-size: 11px;
}
.clubsCode a {
    background: #5d3f80;
    padding: 12px 10px 11px !important;
    font-size: 11px;
}
.naacCode a{
    background: #3096d0;
    padding: 12px 10px 11px !important;
    font-size: 11px;
}
.login-icon a{
    background: #ced4da;
    padding: 12px 10px 11px !important;
    font-size: 11px;
}

.admissionsBtn p {
    background: #c942ac;
    padding: 13px 10px 11px !important;
    font-size: 11px;

}

.admissionBtn a {
    background: #f81300;
    padding: 13px 10px 11px !important;
    font-size: 11px;
    margin-right: 0px;

}
.form-control::placeholder{
    font-size: 12px;
}

b,
strong {
    font-weight: 500;
}

.welcomeText {
    color: #f81300;
    font-size: 55px;
}

.slider-content h5 {
    color: #fff;
}

.switch-box {
    display: block;
}

.buy-now-btn {
    display: none;
}

.others-option-for-responsive .dot-menu {
    display: none !important;
}

.f_none {
    float: none !important;
}

.crg-td-list {
    width: 100%;
    overflow: hidden;
}

.crg-td-list ul li,
.crg-td-list2 ul li {
    width: -webkit-calc(25% - 25px);
    width: -moz-calc(25% - 25px);
    width: calc(25% - 25px);
    max-width: 350px;
    margin: 0px 21px 20px 0px;
    display: inline-block;
    text-align: left;
}

.crg-td-list2 ul li {
    width: 100% !important;
    flex-shrink: 0;
    margin: 0px 20px 0px 0px;
}

.crg-td-list2 ul li {
    width: 100% !important;
    flex-shrink: 0;
    margin: 0px 20px 0px 0px;
}

.crg-td-list2 ul li {
    width: 320px;
    flex-shrink: 0;
    margin: 0px 20px 0px 0px;
}

.crg-td-list ul li div.crg-img,
.crg-td-list2 ul li div.crg-img {
    background: #000000;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100%;
    height: 170px;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, .075);
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
}

/* .crg-td-list ul li .crg-img img,
.crg-td-list2 ul li .crg-img img {
    width: 100%;
    height: 200px margin: -30px 0px 0px 0px;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.crg-td-list ul li .crg-img img,
.crg-td-list2 ul li .crg-img img {
    width: 100%;
    height: 200px margin: -30px 0px 0px 0px;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
} */

.crg-td-list ul li .crg-info,
.crg-td-list2 ul li .crg-info {
    width: -webkit-calc(100% - 20px);
    width: -moz-calc(100% - 20px);
    width: calc(100% - 20px);
    background: #ffffff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, .075);
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    display: block;
    margin: -15px auto 0px;
    padding: 15px 20px;
    position: relative;
    z-index: 1;
    border: 1px solid #d5d1d196;
}

.crg-td-list ul li .crg-info:before,
.crg-td-list2 ul li .crg-info:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.crg-td-list ul li .crg-title,
.crg-td-list2 ul li .crg-title {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 26px;
    margin: 0px 0px 5px 0px;
    height: 52px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.crg-td-list ul li .crg-info>*,
.crg-td-list2 ul li .crg-info>* {
    position: relative;
    z-index: 1;
}

.crg-td-list ul li .crg-info>*,
.crg-td-list2 ul li .crg-info>* {
    position: relative;
    z-index: 1;
}

.crg-td-list ul li .crg-info .crg-view ul,
.crg-td-list2 ul li .crg-info .crg-view ul {
    padding: 0px;
    float: none;
    width: 100%;
}

.crg-td-list2 ul li .crg-info .crg-view ul li {
    position: static;
    flex-shrink: unset;
}

.crg-td-list ul li .crg-info .crg-view ul li a.crg-link,
.crg-td-list2 ul li .crg-info .crg-view ul li a.crg-link2 {
    height: 34px;
    line-height: 26px;
    padding: 4px 10px;
    border: 1px solid #f1f1f1;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    color: #f81300;
    display: block;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-transform: uppercase;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    margin: 0px;
    letter-spacing: 0px;
}

.crg-submenu {
    position: absolute;
    bottom: 150px;
    left: 0px;
    right: 0px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
    text-align: center;
}

.crg-submenu>div {
    width: auto;
    background: #fff;
    padding: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    position: relative;
    margin: 0px auto;
    display: inline-block;
}

.crg-submenu>div::before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
}

.crg-submenu ul,
.crg-td-list2 ul li .crg-info .crg-view ul li .crg-submenu ul {
    justify-content: center;
}

.crg-submenu {
    position: absolute;
    bottom: 150px;
    left: 0px;
    right: 0px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
    text-align: center;
}

.crg-td-list2 ul li .crg-info .crg-view ul li {
    position: static;
    flex-shrink: unset;
}

.crg-submenu ul,
.crg-td-list2 ul li .crg-info .crg-view ul li .crg-submenu ul {
    justify-content: center;
}

.crg-submenu ul li,
.crg-td-list2 ul li .crg-info .crg-view ul li .crg-submenu ul li {
    width: 60px;
}

.crg-td-list ul li:hover .crg-info .crg-view ul li .crg-submenu,
.crg-td-list ul li .crg-info .crg-view ul li:hover .crg-submenu,
.crg-td-list2 ul li.active .crg-info .crg-view ul li .crg-submenu {
    bottom: 100%;
    visibility: visible;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
}

.crg-td-list ul li .crg-info:before,
.crg-td-list2 ul li .crg-info:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #f1f1f180;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.crg-td-list ul li:hover .crg-info:before,
.crg-td-list2 ul li.active .crg-info:before {
    height: 100%;
}

.course-section2 ol,
ul {
    padding-left: 0rem;
}

.campus-title p {
    text-align: justify;
}

.text-red {
    color: #f81300;
}

.wed-hom-footer {
    /* margin-top: 60px; */
    /* padding: 0px 0px 65px 0px; */
    background: #5d3f80 !important;
}

.wed-foot-link {
    border-top: 1px groove #6a83a0;
    /* border-bottom: 1px groove #ffffff; */
    padding: 35px 0px;
    /* margin-top: 35px; */
}

.wed-foot-link div {
    border-right: 1px groove #ffffff;
}

.wed-hom-footer h4 {
    font-size: 16px;
    color: #d5d5d5;

    text-transform: uppercase;
}

.wed-foot-link ul li {
    width: 50%;
    float: left;
    list-style: none;
    line-height: 32px;
    font-weight: 500;
}

.wed-foot-link ul li a {
    color: #ffffff;
    font-size: 12px;
}

.wed-foot-link-1 {
    padding-top: 25px;
}

.wed-foot-link.socialMediaIcons ul li a {
    width: 20% !important;

    list-style: none;
    line-height: 32px;
    font-weight: 500;
}

.wed-foot-link div {
    border-right: 1px groove #ffffff;
}

.wed-hom-footer h4 {
    font-size: 15px;
    color: #d5d5d5;

    text-transform: uppercase;
}

.wed-foot-link div:last-child {
    border-right: 0px solid #dadada;
}

.wed-hom-footer p {
    font-size: 13px;
    color: #ffffff;
}

.mailLink {
    color: #fff;
}

.footerInput {
    background-color: #fff;
    border: 1px solid #cecece;
    border-radius: 2px;
    outline: none;
    height: 2rem;
    width: 95%;
    margin: 0 0 20px 0;
    padding: 8px 7px;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s
}

.input-field {
    position: relative;
    margin-top: 1rem;
}

.light-btn {
    background: #6a111a;
    transition: background 350ms ease-in-out;
    padding: 6px 10px;
    line-height: 30px;
    line-height: 30px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    display: block;
    text-align: center;
    color: #ffffff;
    width: 100%;
}

.waves-input-wrapper .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}

.footerBtn {
    background: #6a111a;
    transition: background 350ms ease-in-out;
    padding: 6px 10px;
    line-height: 30px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    display: block;
    text-align: center;
    color: #ffffff;
    width: 100%;
    border: 0;
}

.socialMediaIcons li {
    list-style: none;
    display: inline;
    color: #fff;
}

.socialMediaIcons li a i {
    width: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 8px;
    color: #ffffff;
}

.copyright p {
    color: #ffffff;
    font-size: 13px;
}

#qopen {
    cursor: pointer;
    position: fixed;
    bottom: 72px;
    right: 95px;
    background: #fa180a;
    color: #ffffff;
    padding: 5px 15px;
    border-radius: 16px;
    z-index: 1000000;
}

#qchat {
    border-radius: 9px;
    /* box-shadow: 0 0 10px #000; */
    /* padding: 0 7px; */
    /* background: #c0233f; */
    position: fixed;
    height: 388px;
    bottom: 41px;
    right: 6px;
    z-index: 1111;
}

#qchat {
    border-radius: 9px;
    /* box-shadow: 0 0 10px #000; */
    /* padding: 0 7px; */
    /* background: #c0233f; */
    position: fixed;
    height: 388px;
    bottom: 41px;
    right: 6px;
    z-index: 1111;
}

.nb-form {
    border-radius: 10px;
    position: fixed;
    z-index: 9999;
    width: 300px;
    background: #FFF;
    transition: all .8s cubic-bezier(.22, .67, .43, 1.22) .2s;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    position: relative;
    padding-bottom: 25px;
}

.nb-form input,
.nb-form textarea {
    font-size: 12px;
    width: 254px;
    max-width: 254px;
    margin-bottom: 10px;
    margin-left: 7px;
    padding: 6px;
    border: none;
    border-radius: 4px;
    color: #999;
    border-bottom: 1px solid #f0f0f0;
}

.nb-form .title {
    background: #fa180a;
    font-size: 18px !important;
    padding: 15px 31px !important;
    color: #fff !important;
    border-radius: 0;
}

#courses {
    /* font-family: Tahoma, Geneva, sans-serif; */
    font-size: 12px;
    /* margin-bottom: 10px; */
    border-radius: 4px;
    border-radius: 4px;
    color: #464646;
    border-bottom: 1px solid #f0f0f0;
    display: block;
    border: 0;
    border-bottom: 1px solid #f0f0f0;
    padding-left: 0px;
    margin-left: 10px;
}

select,
select option {
    background: #ffffff;
    font-size: 14px;
}

.send-btn button {
    padding: 6px 30px;
    border: 0;
    border-radius: 5px;
    color: white;
    background-color: #c71e2f;
}

/* #qopen {
    background-image: linear-gradient(180deg, #ff545c61 0%, #00000082 100%) !important;
}

#qclose {
    cursor: pointer;
    position: absolute;
    top: -31px;
    z-index: 111;
    background: #fa180a;
    padding: 8px 10px;
    color: #fff;
    right: -5px;
    right: -5px;
    border-radius: 50%;
} */

.icon-float ul li a i {
    padding: 8px;
    width: 40px;
    height: 32px;
    text-align: center;
    color: #fff;
}

.icon-float {
    position: fixed;
    /* left: 0px; */
    top: 35%;
    z-index: 999;
    right: 0px;
}

.icon-float ul li a {
    display: block;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 0px;
}

.fb1 {
    background: #3b5998;
}

.icon-float ul li {
    list-style-type: none;
}

.insta {
    background: rgb(221, 42, 123);
}

.li1 {
    background: #0077b5;
}

.sh1 {
    background: #333;
    border-bottom-left-radius: 5px;
}

.icon-float ul li a:hover {
    transform: rotateY(-180deg);
}

.icon-float ul li a:hover i {
    transform: rotateY(180deg);
}

.header-left-content marquee {
    line-height: 10px;
}

.news-heading:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #f26838;
    border-width: 0px;
    margin-top: -26px;
}








@media only screen and (max-width:767px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pt-100 {
        padding-top: 50px
    }

    .pb-100 {
        padding-bottom: 50px
    }

    .pb-70 {
        padding-bottom: 20px
    }

    .pt-70 {
        padding-top: 50px
    }

    .mt-20 {
        margin-top: 0
    }

    .pl-20 {
        padding-left: 0
    }

    .pl-15 {
        padding-left: 0
    }

    .mr-20 {
        margin-right: 0
    }

    .pr-20 {
        padding-right: 0
    }

    p {
        font-size: 14px
    }

    .form-control {
        height: 45px
    }

    .mb-30 {
        margin-bottom: 30px
    }

    .default-btn {
        font-size: 13px;
        padding: 10px 20px
    }

    .default-btn.mr-20 {
        margin-right: 10px
    }

    .read-more-btn {
        font-size: 14px
    }

    .section-title {
        margin: -10px auto 30px
    }

    .section-title h2 {
        font-size: 24px
    }

    .header-left-content {
        text-align: center;
        margin-bottom: 15px
    }

    .header-right-content {
        text-align: center
    }

    .modal.right .modal-dialog {
        width: 300px
    }

    .sidebarModal.modal .modal-body .sidebar-content {
        margin-top: 20px;
        margin-bottom: 30px
    }

    .sidebarModal.modal .modal-body .sidebar-content p {
        margin-bottom: 30px
    }

    .sidebarModal.modal .modal-body .sidebar-contact-info .info-list {
        margin-top: 20px
    }

    .sidebarModal.modal .modal-body .sidebar-contact-info .info-list li {
        margin-bottom: 10px
    }

    .sidebarModal.modal .modal-body .sidebar-social-list {
        margin-top: 20px
    }

    .sidebarModal.modal .modal-body .contact-form {
        margin-top: 20px
    }

    .banner-area {
        height: auto;
        padding-bottom: 0;
        background-color: transparent
    }

    .banner-area .container-fluid {
        margin: auto;
        padding-left: 0
    }

    .slider-item {
        padding-top: 0px;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0
    }

    .slider-content {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 25px;

    }

    .slider-content h1 {
        font-size: 30px;
        margin-bottom: 15px
    }

    .slider-content p {
        margin-bottom: 25px
    }

    .slider-content.style2 {
        background-color: transparent;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px
    }

    .slider-content.style2 h1 {
        color: #fff
    }

    .slider-content.style2 p {
        color: #fff
    }
    .campus-image{
        margin-bottom: 0;
    }

    .owl-thumbs {
        display: none
    }

    .hero-slider {
        position: relative
    }

    .hero-slider.owl-theme .owl-dots {
        display: none
    }

    .hero-slider.owl-theme .owl-nav [class*=owl-] {
        position: absolute;
        left: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px;
        right: 60px;
        bottom: 0px;
        margin: auto
    }

    .hero-slider.owl-theme .owl-nav [class*=owl-].owl-next {
        left: 110px
    }

    .hero-slider2 {
        position: relative
    }

    .hero-slider2.owl-theme .owl-nav [class*=owl-] {
        position: absolute;
        left: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px;
        right: 70px;
        bottom: -90px;
        margin: auto;
        opacity: 1
    }

    .hero-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
        left: 140px
    }

    .banner-area.banner-area-five video {
        width: auto;
        height: 420px
    }

    .banner-area.banner-area-five .slider-item {
        padding-left: 0
    }

    .single-academics-card {
        padding: 20px
    }

    .single-academics-card .academic-top-content {
        padding-left: 30px
    }

    .single-academics-card .academic-top-content i {
        font-size: 24px
    }

    .single-academics-card .academic-top-content h3 {
        font-size: 18px
    }

    .single-academics-card2 {
        height: 400px;
        padding: 20px
    }

    .single-academics-card2 .serial {
        left: 20px;
        top: 20px
    }

    .single-academics-card2 .academic-content {
        padding: 20px
    }

    .single-academics-card2 .academic-content .academic-top-content {
        padding-left: 25px
    }

    .single-academics-card2 .academic-content .academic-top-content h3 {
        font-size: 20px
    }

    .single-academics-card3 {
        padding: 20px
    }

    .single-academics-card3 .icon {
        margin-bottom: 15px
    }

    .single-academics-card3 h3 {
        margin-bottom: 10px
    }

    .single-academics-card3 p {
        margin-bottom: 10px
    }

    .serch-content {
        padding: 20px
    }

    .serch-content h3 {
        font-size: 20px;
        margin-bottom: 13px
    }

    .serch-content .form-group .src-btn {
        top: 10px
    }

    .category-content {
        padding: 20px
    }

    .category-content h3 {
        font-size: 20px;
        margin-bottom: 13px
    }

    .location-type {
        padding-bottom: 20px;
        margin-bottom: 20px
    }

    .location-type h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .program-level h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .academics-details .details h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .academics-details .details p {
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee {
        padding-bottom: 20px;
        margin-bottom: 20px
    }

    .academics-details .regiStartion-and-fee h3 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee p {
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee .list ul li {
        margin-bottom: 8px
    }

    .academics-details .prepare-exam h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .academics-details .prepare-exam p {
        margin-bottom: 15px
    }

    .academics-details .prepare-exam .exam-card {
        padding-left: 20px;
        margin-bottom: 20px
    }

    .academics-details .prepare-exam .exam-card h4 {
        font-size: 18px;
        margin-bottom: 10px
    }

    .academics-details .prepare-exam .exam-card h4::before {
        left: -20px
    }

    .academics-list {
        padding: 20px
    }

    .academics-list h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .related-download {
        padding: 14px
    }

    .related-download h3 {
        font-size: 20px
    }

    .related-download ul li {
        padding: 17px;
        padding-left: 70px
    }

    .campus-content .campus-title {
        margin-bottom: 25px
    }

    .campus-content .campus-title h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .campus-content .list {
        margin-bottom: 10px
    }

    .campus-content.style-2 {
        margin-left: 0;
        padding: 0;
        box-shadow: none
    }

    .counter-card {
        margin-bottom: 20px
    }

    .counter-card h1 {
        font-size: 30px
    }

    .counter-card h1 .target.heading-color {
        font-size: 30px
    }

    .single-campus-card .campus-content {
        padding: 20px
    }

    .single-campus-card .campus-content span {
        margin-bottom: 10px;
        font-size: 14px
    }

    .single-campus-card .campus-content h3 {
        font-size: 19px
    }

    .campus-slider {
        margin-bottom: -20px
    }

    .campus-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .campus-slider.owl-theme .owl-dots {
        display: none
    }

    .campus-slider2 {
        margin-bottom: -20px
    }

    .campus-slider2.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .campus-slider2.owl-theme .owl-dots {
        display: none
    }

    .estemate-form {
        margin-top: 0;
        padding: 20px;
        margin-bottom: 30px
    }

    .single-courses-card .courses-content {
        padding: 25px 20px 20px
    }

    .single-courses-card .courses-content .admin-profile {
        margin-bottom: 15px
    }

    .single-courses-card .courses-content h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .single-courses-card.style3 .admin-profile {
        margin-bottom: 20px
    }

    .single-courses-card.style3 .admin-profile::before {
        display: none
    }

    .single-courses-card.style3 .courses-content h3 {
        margin-bottom: 30px
    }

    .courses-slider.mb-20 {
        margin-bottom: -20px
    }

    .courses-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .courses-slider.owl-theme .owl-dots {
        display: none
    }

    .courses-details .courses-card {
        margin-bottom: 25px
    }

    .courses-details .courses-card h2 {
        font-size: 22px
    }

    .courses-details .courses-card .list ul .teacher {
        margin-bottom: 30px
    }

    .reply-area p {
        margin-bottom: 15px
    }

    .description {
        padding-bottom: 15px
    }

    .description .nav-tabs {
        margin-bottom: 25px;
        box-shadow: none
    }

    .description .nav-tabs .nav-link {
        padding: 10px 20px;
        margin-bottom: 15px
    }

    .overview .learn {
        margin-bottom: 20px
    }

    .overview .learn h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .overview .learn p {
        margin-bottom: 15px
    }

    .overview .overview-box {
        padding-left: 20px;
        margin-bottom: 20px
    }

    .overview .overview-box h4::before {
        left: -20px
    }

    .curriculum h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .curriculum .curriculum-list {
        padding: 15px
    }

    .curriculum .curriculum-list ul li {
        padding-right: 75px
    }

    .instructor .instructor-image {
        margin-bottom: 20px
    }

    .instructor .instructor-content .name {
        margin-bottom: 20px
    }

    .instructor .instructor-content .mb-30 {
        margin-bottom: 20px
    }

    .instructor .instructor-content .quick-contact h4 {
        margin-bottom: 10px
    }

    .single-reviews-card {
        padding: 20px;
        padding-left: 20px
    }

    .single-reviews-card img {
        position: unset;
        transform: translateY(0);
        margin-bottom: 15px
    }

    .single-reviews-card .date {
        margin-bottom: 15px
    }

    .review-form h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .review-form .form-group {
        margin-bottom: 20px
    }

    .enroll-courses {
        padding: 20px
    }

    .enroll-courses .enroll-img {
        margin-bottom: 20px
    }

    .enroll-courses .list {
        margin-bottom: 20px
    }

    .enroll-courses .list ul li {
        padding-bottom: 10px;
        margin-bottom: 10px
    }

    .admission-content .admission-image {
        margin-bottom: 20px
    }

    .admission-content .admission-image .icon a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 22px
    }

    .admission-content .admission-image .icon::before {
        height: 50px;
        width: 50px
    }

    .admission-content .admission-image .icon::after {
        height: 55px;
        width: 55px
    }

    .admission-content .query p {
        margin-bottom: 20px
    }

    .admission-left-content {
        margin-bottom: 30px
    }

    .admission-left-content h2 {
        font-size: 24px
    }

    .admission-right-content {
        text-align: left
    }

    .admission-right-content .icon {
        right: 60px
    }

    .how-to-apply h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .how-to-apply p {
        margin-bottom: 15px
    }

    .submit h3 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .financial h3 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .additional-information {
        padding: 20px
    }

    .additional-information .list ul li {
        padding: 12px
    }

    .single-program-card {
        padding: 20px
    }

    .single-program-card h3 {
        margin-bottom: 15px
    }

    .single-health-care-card .health-care-content {
        padding: 20px
    }

    .single-health-care-card .health-care-content h3 {
        font-size: 20px
    }

    .single-health-care-card.style1 .health-care-content {
        max-width: none;
        position: unset
    }

    .single-health-care-card.style2 {
        padding-top: 0
    }

    .single-health-care-card.style2 .health-care-content {
        max-width: none;
        position: unset
    }

    .more-health-care.style2 {
        text-align: center !important;
        padding-right: 0
    }

    .health-care-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .health-care-slider.owl-theme .owl-dots {
        display: none
    }

    .health-details .top-content h2 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .health-details .top-content p {
        margin-bottom: 15px
    }

    .health-details .health-care h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .health-details .health-care p {
        margin-bottom: 15px
    }

    .health-details .health-care-access h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .madical-care-content .medical-card {
        margin-bottom: 20px
    }

    .madical-care-content .medical-card .content {
        padding: 15px 0 7px
    }

    .madical-care-content .medical-care {
        margin-bottom: 20px
    }

    .madical-care-content .medical-care h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .madical-care-content .medical-care .list ul li {
        font-size: 14px
    }

    .tranding {
        padding: 20px
    }

    .single-events-card .events-image .date {
        left: 20px;
        padding: 10px 20px
    }

    .single-events-card .events-content {
        padding: 20px
    }

    .single-events-card .events-content .admin {
        margin-bottom: 15px
    }

    .single-events-card .events-content h3 {
        font-size: 20px
    }

    .events-slider.mb-20 {
        margin-bottom: -20px
    }

    .events-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .events-slider.owl-theme .owl-dots {
        display: none
    }

    .events-details-left-content .events-image {
        margin-bottom: 25px
    }

    .events-details-left-content .about-lecturer img {
        margin-bottom: 20px
    }

    .events-details-left-content .about-lecturer h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .events-details-left-content .about-lecturer p {
        margin-bottom: 20px
    }

    .events-details-left-content .about-lecturer .address {
        margin-bottom: 20px
    }

    .events-details-left-content .about-lecturer .address h4 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .meetings {
        padding-bottom: 10px;
        margin-bottom: 20px
    }

    .meetings h2 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .meetings p {
        margin-bottom: 15px
    }

    .next-workshop {
        margin-bottom: 20px
    }

    .next-workshop h3 {
        font-size: 20px
    }

    .next-workshop .list ul li {
        margin-bottom: 8px;
        padding-left: 110px
    }

    .brought h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .brought p {
        margin-bottom: 15px
    }

    .podcast-content {
        background-color: #f4f6f9;
        padding: 20px
    }

    .podcast-content h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .single-podcasts-card.style2 .podcast-content {
        padding: 20px
    }

    .single-podcasts-card.style2 .podcast-content h3 {
        font-size: 20px
    }

    .single-podcasts-card.style3 .podcasts-image .play-btn {
        left: 20px;
        bottom: 20px
    }

    .single-podcasts-card.style3 .podcasts-image .episodes p {
        padding: 8px 12px;
        left: 20px;
        top: 20px
    }

    .single-podcasts-card.style3 .podcast-content {
        background-color: #fff;
        padding: 20px
    }

    .single-podcasts-card.style3 .podcast-content .date p {
        margin-bottom: 0
    }

    .categories {
        padding: 20px
    }

    .single-stories-card .stories-content {
        padding: 20px 20px 15px
    }

    .single-news-card .news-content {
        padding: 20px
    }

    .single-news-card .news-content .list ul li {
        margin-right: 10px
    }

    .single-news-card .news-content .list ul li:last-child {
        margin-right: 0
    }

    .single-news-card .news-content h3 {
        font-size: 18px
    }

    .news-slider {
        margin-bottom: -20px
    }

    .news-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 0;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .news-slider.owl-theme .owl-dots {
        display: none
    }

    .latest-news-simple-card {
        height: 350px
    }

    .latest-news-simple-card .news-content {
        padding: 15px
    }

    .latest-news-simple-card .news-content h3 {
        font-size: 22px
    }

    .latest-news-card-area h3 {
        font-size: 20px
    }

    .category-list {
        padding: 20px
    }

    .category-list h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .related-post-area {
        padding: 20px
    }

    .related-post-area h3 {
        font-size: 20px
    }

    .related-post-area .related-post-box .related-post-content h4 {
        font-size: 15px
    }

    .news-details .news-simple-card .list {
        margin-bottom: 10px
    }

    .news-details .news-simple-card h2 {
        font-size: 22px;
        margin-bottom: 20px;
        margin-bottom: 15px
    }

    .news-details .news-simple-card p {
        margin-bottom: 15px
    }

    .news-details .theory .theory-box {
        padding-left: 20px;
        margin-bottom: 20px
    }

    .news-details .theory .theory-box h4::before {
        left: -20px
    }

    .news-details .blog-img-and-content {
        margin-bottom: 15px
    }

    .tags-and-share .tags {
        margin-bottom: 20px
    }

    .tags-and-share .share {
        text-align: left
    }

    .comments {
        padding-bottom: 0;
        margin-bottom: 20px
    }

    .comments h3 {
        font-size: 20px
    }

    .comments .single-comments-box {
        padding: 0
    }

    .comments img {
        position: unset;
        transform: translateY(0);
        margin-bottom: 20px
    }

    .comments h4 {
        margin-bottom: 8px;
        font-size: 18px
    }

    .comments .date {
        margin-bottom: 15px
    }

    .reply-area h3 {
        font-size: 20px
    }

    .reply-area .reply-form .form-group {
        margin-bottom: 20px
    }

    .paginations ul li a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 16px
    }

    .footer-logo-area p {
        margin-bottom: 20px
    }

    .footer-logo-area .contact-list ul li {
        margin-bottom: 8px
    }

    .footer-logo-area .contact-list ul li a {
        font-size: 14px
    }

    .footer-widjet h3 {
        font-size: 20px;
        margin-bottom: 18px
    }

    .footer-widjet .list ul li a {
        font-size: 14px
    }



    .copyright .copy {
        text-align: center
    }

    .copyright .social-content {
        text-align: center;
        margin-bottom: 20px
    }

    .page-banner-area {
        padding-bottom: 60px;
        padding-top: 60px
    }

    .page-banner-area .page-banner-content h1 {
        font-size: 30px
    }

    .page-banner-area .page-banner-content ul li {
        font-size: 14px
    }

    .alumni-left-content h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .single-alumni-events-card {
        padding: 0
    }

    .single-alumni-events-card .alumni-content {
        padding: 20px
    }

    .single-alumni-events-card .alumni-content h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .alumni-right-content h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .single-alumoni-updates-card {
        padding: 0
    }

    .single-alumoni-updates-card .updates-content {
        padding: 20px
    }

    .single-alumoni-updates-card .updates-content h3 {
        font-size: 18px
    }

    .interview-content {
        position: relative
    }

    .interview-content::before {
        position: absolute;
        content: "";
        background-color: rgba(31, 44, 117, .7);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0
    }

    .interview-content .interview {
        position: relative;
        padding-left: 15px;
        padding-right: 15px
    }

    .interview-content .interview span {
        color: #e32845
    }

    .interview-content .interview h2 {
        color: #fff
    }

    .interview-content .interview p {
        color: #fff
    }

    .faq-title h2 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .faq-title p {
        margin-bottom: 20px
    }

    .faq-image {
        margin-bottom: 20px
    }

    .accordion .accordion-item .accordion-button {
        padding: 12px;
        font-size: 17px
    }

    .login {
        padding: 20px
    }

    .register {
        padding: 20px
    }

    .register .default-btn {
        margin-top: 10px
    }

    .password {
        padding: 15px
    }

    .privacy-content h2 {
        font-size: 22px
    }

    .privacy-content p {
        margin-bottom: 15px
    }

    .privacy-content h4 {
        font-size: 20px
    }

    .privacy-content .list ul {
        margin-bottom: 10px
    }

    .privacy-content .list ul li {
        font-size: 14px
    }

    .condition-content h2 {
        font-size: 22px;
        margin-bottom: 20px
    }

    .condition-content p {
        margin-bottom: 15px
    }

    .condition-content h4 {
        font-size: 20px
    }

    .condition-content .list ul {
        margin-bottom: 10px
    }

    .condition-content .list ul li {
        font-size: 14px
    }

    .coming-soon-area .coming-soon h1 {
        font-size: 30px
    }

    .coming-soon-area .coming-soon .newsletter-form .default-btn {
        position: unset;
        margin-top: 20px
    }

    .error-area .top-content ul li {
        font-size: 100px;
        margin-right: 15px;
        margin-bottom: 15px
    }

    .error-area h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .error-area p {
        margin-bottom: 20px
    }

    .contacts-form {
        padding: 20px
    }

    .contacts-form h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .contacts-form .form-group {
        margin-bottom: 20px
    }

    .contact-and-address h2 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .contact-and-address .contact-and-address-content {
        margin-bottom: 20px;
        padding-bottom: 0
    }

    .contact-and-address .contact-and-address-content .contact-card {
        padding: 20px
    }

    .contact-and-address .contact-and-address-content .contact-card h4 {
        margin-bottom: 12px
    }

    .contact-and-address .contact-and-address-content .contact-card p {
        margin-bottom: 8px
    }

    .contact-and-address .social-media h3 {
        font-size: 22px;
        margin-bottom: 10px
    }

    .contact-and-address .social-media p {
        margin-bottom: 20px
    }

    .go-top {
        height: 40px;
        width: 40px;
        line-height: 40px
    }
}

@media only screen and (min-width:576px) and (max-width:767px) {
    .container-fluid {
        max-width: 540px !important
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pt-100 {
        padding-top: 50px
    }

    .pb-100 {
        padding-bottom: 50px
    }

    .pb-70 {
        padding-bottom: 20px
    }

    .pt-70 {
        padding-top: 50px
    }

    .mt-20 {
        margin-top: 0
    }

    .pl-20 {
        padding-left: 0
    }

    .pl-15 {
        padding-left: 0
    }

    .mr-20 {
        margin-right: 0
    }

    .pr-20 {
        padding-right: 0
    }

    p {
        font-size: 14px
    }

    .form-control {
        height: 45px
    }

    .mb-30 {
        margin-bottom: 30px
    }

    .container-fluid {
        max-width: 720px !important
    }

    .default-btn {
        font-size: 13px;
        padding: 10px 20px
    }

    .default-btn.mr-20 {
        margin-right: 10px
    }

    .read-more-btn {
        font-size: 15px
    }

    .section-title {
        margin: -10px auto 30px
    }

    .section-title h2 {
        font-size: 24px
    }

    .top-header-area .container-fluid {
        max-width: auto
    }

    .header-left-content p {
        font-size: 11px
    }

    .header-right-content .list ul li {
        margin-right: 10px
    }

    .header-right-content .list ul li a {
        font-size: 11px
    }

    .modal.right .modal-dialog {
        width: 300px
    }

    .sidebarModal.modal .modal-body .sidebar-content {
        margin-top: 20px;
        margin-bottom: 30px
    }

    .sidebarModal.modal .modal-body .sidebar-content p {
        margin-bottom: 30px
    }

    .sidebarModal.modal .modal-body .sidebar-contact-info .info-list {
        margin-top: 20px
    }

    .sidebarModal.modal .modal-body .sidebar-contact-info .info-list li {
        margin-bottom: 10px
    }

    .sidebarModal.modal .modal-body .sidebar-social-list {
        margin-top: 20px
    }

    .sidebarModal.modal .modal-body .contact-form {
        margin-top: 20px
    }

    .banner-area {
        padding-bottom: 0;
        background-color: transparent
    }

    .banner-area .container-fluid {
        margin: auto;
        padding-left: 0
    }

    .slider-item {
        padding-top: 60px;
        padding-bottom: 70px;
        padding-left: 15px;
        padding-right: 15px
    }

    .slider-content h1 {
        font-size: 30px;
        margin-bottom: 15px
    }

    .slider-content p {
        margin-bottom: 25px
    }

    .slider-content.style2 {
        background-color: transparent;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px
    }

    .slider-content.style2 h1 {
        color: #fff
    }

    .slider-content.style2 p {
        color: #fff
    }

    .owl-thumbs {
        display: none
    }

    .hero-slider {
        position: relative
    }

    .hero-slider.owl-theme .owl-dots {
        display: none
    }

    .hero-slider.owl-theme .owl-nav [class*=owl-] {
        position: absolute;
        left: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px;
        right: 60px;
        bottom: 40px;
        margin: auto
    }

    .hero-slider.owl-theme .owl-nav [class*=owl-].owl-next {
        left: 110px
    }

    .hero-slider2 {
        position: relative
    }

    .hero-slider2.owl-theme .owl-nav [class*=owl-] {
        position: absolute;
        left: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px;
        right: 70px;
        bottom: -50px;
        margin: auto;
        opacity: 1
    }

    .hero-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
        left: 140px
    }

    .banner-area.banner-area-five .slider-item {
        padding-left: 35px
    }

    .eduction-area {
        padding-top: 50px
    }

    .single-education-card {
        margin-left: 15px;
        margin-right: 15px
    }

    .single-academics-card {
        padding: 20px
    }

    .single-academics-card .academic-top-content {
        padding-left: 30px
    }

    .single-academics-card .academic-top-content i {
        font-size: 24px
    }

    .single-academics-card .academic-top-content h3 {
        font-size: 18px
    }

    .single-academics-card2 {
        height: 400px;
        padding: 20px
    }

    .single-academics-card2 .serial {
        left: 20px;
        top: 20px
    }

    .single-academics-card2 .academic-content {
        padding: 20px
    }

    .single-academics-card2 .academic-content .academic-top-content {
        padding-left: 25px
    }

    .single-academics-card2 .academic-content .academic-top-content h3 {
        font-size: 20px
    }

    .single-academics-card3 {
        padding: 20px
    }

    .single-academics-card3 .icon {
        margin-bottom: 15px
    }

    .single-academics-card3 h3 {
        margin-bottom: 10px
    }

    .single-academics-card3 p {
        margin-bottom: 10px
    }

    .serch-content {
        padding: 20px
    }

    .serch-content h3 {
        font-size: 20px;
        margin-bottom: 13px
    }

    .serch-content .form-group .src-btn {
        top: 10px
    }

    .category-content {
        padding: 20px
    }

    .category-content h3 {
        font-size: 20px;
        margin-bottom: 13px
    }

    .location-type {
        padding-bottom: 20px;
        margin-bottom: 20px
    }

    .location-type h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .program-level h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .academics-details .details h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .academics-details .details p {
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee {
        padding-bottom: 20px;
        margin-bottom: 20px
    }

    .academics-details .regiStartion-and-fee h3 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee p {
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee .list ul li {
        margin-bottom: 8px
    }

    .academics-details .prepare-exam h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .academics-details .prepare-exam p {
        margin-bottom: 15px
    }

    .academics-details .prepare-exam .exam-card {
        padding-left: 20px;
        margin-bottom: 20px
    }

    .academics-details .prepare-exam .exam-card h4 {
        font-size: 18px;
        margin-bottom: 10px
    }

    .academics-details .prepare-exam .exam-card h4::before {
        left: -20px
    }

    .academics-list {
        padding: 20px
    }

    .academics-list h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .related-download {
        padding: 14px
    }

    .related-download h3 {
        font-size: 20px
    }

    .related-download ul li {
        padding: 17px;
        padding-left: 70px
    }

    .campus-content .campus-title {
        margin-bottom: 25px
    }

    .campus-content .campus-title h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .campus-content .list {
        margin-bottom: 10px
    }

    .campus-content.style-2 {
        margin-left: 0;
        padding: 0;
        box-shadow: none
    }

    .counter-card {
        margin-bottom: 20px
    }

    .counter-card h1 {
        font-size: 30px
    }

    .counter-card h1 .target.heading-color {
        font-size: 30px
    }

    .single-campus-card .campus-content {
        padding: 20px
    }

    .single-campus-card .campus-content span {
        margin-bottom: 10px
    }

    .single-campus-card .campus-content h3 {
        font-size: 20px
    }

    .estemate-form {
        margin-top: 0;
        padding: 20px;
        margin-bottom: 30px
    }

    .campus-slider {
        margin-bottom: -20px
    }

    .campus-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 15px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .campus-slider.owl-theme .owl-dots {
        display: none
    }

    .campus-slider2 {
        margin-bottom: -20px
    }

    .campus-slider2.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .campus-slider2.owl-theme .owl-dots {
        display: none
    }

    .single-courses-card .courses-content {
        padding: 25px 20px 20px
    }

    .single-courses-card .courses-content .admin-profile {
        margin-bottom: 15px
    }

    .single-courses-card .courses-content h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .single-courses-card.style3 .admin-profile {
        margin-bottom: 20px
    }

    .single-courses-card.style3 .admin-profile::before {
        display: none
    }

    .single-courses-card.style3 .courses-content h3 {
        margin-bottom: 30px
    }

    .courses-slider.mb-20 {
        margin-bottom: -20px
    }

    .courses-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .courses-slider.owl-theme .owl-dots {
        display: none
    }

    .reply-area p {
        margin-bottom: 15px
    }

    .description {
        padding-bottom: 15px
    }

    .description .nav-tabs {
        margin-bottom: 25px
    }

    .description .nav-tabs .nav-link {
        padding: 10px 20px
    }

    .overview .learn {
        margin-bottom: 20px
    }

    .overview .learn h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .overview .learn p {
        margin-bottom: 15px
    }

    .overview .overview-box {
        padding-left: 20px;
        margin-bottom: 20px
    }

    .overview .overview-box h4::before {
        left: -20px
    }

    .curriculum h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .instructor {
        margin-bottom: 20px
    }

    .instructor .instructor-image {
        margin-bottom: 20px
    }

    .instructor .instructor-content .name {
        margin-bottom: 20px
    }

    .instructor .instructor-content .mb-30 {
        margin-bottom: 20px
    }

    .instructor .instructor-content .quick-contact h4 {
        margin-bottom: 10px
    }

    .review-form {
        margin-bottom: 20px
    }

    .review-form h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .review-form .form-group {
        margin-bottom: 20px
    }

    .single-reviews-card {
        padding: 20px;
        padding-left: 130px
    }

    .single-reviews-card .date {
        margin-bottom: 15px
    }

    .single-reviews-card img {
        left: 20px
    }

    .enroll-courses {
        padding: 20px
    }

    .enroll-courses .enroll-img {
        margin-bottom: 20px
    }

    .enroll-courses .list {
        margin-bottom: 20px
    }

    .enroll-courses .list ul li {
        padding-bottom: 10px;
        margin-bottom: 10px
    }

    .admission-content .admission-image {
        margin-bottom: 20px
    }

    .admission-content .query p {
        margin-bottom: 20px
    }

    .admission-left-content h2 {
        font-size: 30px
    }

    .admission-right-content .icon {
        right: 20px
    }

    .how-to-apply h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .how-to-apply p {
        margin-bottom: 15px
    }

    .submit h3 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .financial h3 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .additional-information {
        padding: 20px
    }

    .additional-information .list ul li {
        padding: 12px
    }

    .single-program-card {
        padding: 20px
    }

    .single-program-card h3 {
        margin-bottom: 15px
    }

    .single-health-care-card .health-care-content {
        padding: 20px
    }

    .single-health-care-card .health-care-content h3 {
        font-size: 20px
    }

    .single-health-care-card.style1 .health-care-content {
        max-width: none;
        position: unset
    }

    .single-health-care-card.style2 {
        padding-top: 0
    }

    .single-health-care-card.style2 .health-care-content {
        max-width: none;
        position: unset
    }

    .more-health-care.style2 {
        text-align: center !important;
        padding-right: 0
    }

    .health-care-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .health-details .top-content h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .health-details .top-content p {
        margin-bottom: 15px
    }

    .health-details .health-care h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .health-details .health-care p {
        margin-bottom: 15px
    }

    .health-details .health-care-access h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .madical-care-content .medical-card {
        margin-bottom: 20px
    }

    .madical-care-content .medical-card .content {
        padding: 15px 0 7px
    }

    .madical-care-content .medical-care {
        margin-bottom: 20px
    }

    .madical-care-content .medical-care h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .madical-care-content .medical-care .list ul li {
        font-size: 14px
    }

    .tranding {
        padding: 20px
    }

    .single-events-card .events-image .date {
        left: 20px;
        padding: 10px 20px
    }

    .single-events-card .events-content {
        padding: 20px
    }

    .single-events-card .events-content .admin {
        margin-bottom: 15px
    }

    .single-events-card .events-content h3 {
        font-size: 20px
    }

    .events-slider.mb-20 {
        margin-bottom: -20px
    }

    .events-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .events-slider.owl-theme .owl-dots {
        display: none
    }

    .events-details-left-content .events-image {
        margin-bottom: 25px
    }

    .events-details-left-content .about-lecturer h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .events-details-left-content .about-lecturer p {
        margin-bottom: 20px
    }

    .events-details-left-content .about-lecturer .address {
        margin-bottom: 20px
    }

    .events-details-left-content .about-lecturer .address h4 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .meetings {
        padding-bottom: 10px;
        margin-bottom: 20px
    }

    .meetings h2 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .meetings p {
        margin-bottom: 15px
    }

    .next-workshop {
        padding-bottom: 20px;
        margin-bottom: 20px
    }

    .next-workshop h3 {
        font-size: 20px
    }

    .next-workshop .list ul li {
        margin-bottom: 8px;
        padding-left: 110px
    }

    .brought h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .brought p {
        margin-bottom: 15px
    }

    .podcast-content h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .categories {
        padding: 20px
    }

    .single-podcasts-card.style3 .podcast-content {
        background-color: #fff;
        padding: 20px
    }

    .single-podcasts-card.style3 .podcast-content .date p {
        margin-bottom: 0
    }

    .single-stories-card .stories-content {
        padding: 20px 20px 15px
    }

    .single-news-card .news-content {
        padding: 20px
    }

    .single-news-card .news-content .list ul li {
        margin-right: 10px
    }

    .single-news-card .news-content .list ul li:last-child {
        margin-right: 0
    }

    .single-news-card .news-content h3 {
        font-size: 20px
    }

    .news-slider {
        margin-bottom: -20px
    }

    .news-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .news-slider.owl-theme .owl-dots {
        display: none
    }

    .latest-news-simple-card {
        height: 350px
    }

    .latest-news-simple-card .news-content {
        padding: 20px
    }

    .latest-news-simple-card .news-content h3 {
        font-size: 24px
    }

    .latest-news-card-area h3 {
        font-size: 20px
    }

    .category-list {
        padding: 20px
    }

    .category-list h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .related-post-area {
        padding: 20px
    }

    .related-post-area h3 {
        font-size: 20px
    }

    .news-details .news-simple-card .list {
        margin-bottom: 10px
    }

    .news-details .news-simple-card h2 {
        font-size: 22px;
        margin-bottom: 20px;
        margin-bottom: 15px
    }

    .news-details .news-simple-card p {
        margin-bottom: 15px
    }

    .news-details .theory .theory-box {
        padding-left: 20px;
        margin-bottom: 20px
    }

    .news-details .theory .theory-box h4::before {
        left: -20px
    }

    .news-details .blog-img-and-content {
        margin-bottom: 15px
    }

    .comments {
        padding-bottom: 0;
        margin-bottom: 20px
    }

    .comments h3 {
        font-size: 20px
    }

    .comments h4 {
        margin-bottom: 8px;
        font-size: 18px
    }

    .comments .date {
        margin-bottom: 15px
    }

    .reply-area h3 {
        font-size: 20px
    }

    .reply-area .reply-form .form-group {
        margin-bottom: 20px
    }

    .paginations ul li a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 16px
    }

    .footer-logo-area p {
        margin-bottom: 20px
    }

    .footer-logo-area .contact-list ul li {
        margin-bottom: 8px
    }

    .footer-logo-area .contact-list ul li a {
        font-size: 14px
    }

    .footer-widjet h3 {
        font-size: 20px;
        margin-bottom: 18px
    }

    .footer-widjet .list ul li a {
        font-size: 14px
    }

    .page-banner-area {
        padding-bottom: 60px;
        padding-top: 60px
    }

    .page-banner-area .page-banner-content h1 {
        font-size: 30px
    }

    .page-banner-area .page-banner-content ul li {
        font-size: 14px
    }

    .alumni-left-content h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .single-alumni-events-card {
        padding: 0
    }

    .single-alumni-events-card .alumni-content h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .alumni-right-content h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .single-alumoni-updates-card {
        padding: 0
    }

    .single-alumoni-updates-card .updates-content h3 {
        font-size: 20px
    }

    .interview-content {
        position: relative
    }

    .interview-content::before {
        position: absolute;
        content: "";
        background-color: rgba(31, 44, 117, .7);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0
    }

    .interview-content .interview {
        position: relative;
        padding-left: 15px;
        padding-right: 15px
    }

    .interview-content .interview span {
        color: #e32845
    }

    .interview-content .interview h2 {
        color: #fff
    }

    .interview-content .interview p {
        color: #fff
    }

    .faq-title h2 {
        font-size: 26px;
        margin-bottom: 15px
    }

    .faq-title p {
        margin-bottom: 20px
    }

    .faq-image {
        margin-bottom: 20px
    }

    .accordion .accordion-item .accordion-button {
        padding: 12px;
        font-size: 17px
    }

    .login {
        padding: 30px
    }

    .register {
        padding: 30px
    }

    .password {
        padding: 20px
    }

    .privacy-content h2 {
        font-size: 26px
    }

    .privacy-content p {
        margin-bottom: 15px
    }

    .privacy-content h4 {
        font-size: 20px
    }

    .privacy-content .list ul {
        margin-bottom: 10px
    }

    .privacy-content .list ul li {
        font-size: 14px
    }

    .condition-content h2 {
        font-size: 26px;
        margin-bottom: 20px
    }

    .condition-content p {
        margin-bottom: 15px
    }

    .condition-content h4 {
        font-size: 20px
    }

    .condition-content .list ul {
        margin-bottom: 10px
    }

    .condition-content .list ul li {
        font-size: 14px
    }

    .coming-soon-area .coming-soon h1 {
        font-size: 36px
    }

    .coming-soon-area .coming-soon .newsletter-form .default-btn {
        padding: 15px 25px
    }

    .error-area .top-content ul li {
        font-size: 120px;
        margin-right: 15px;
        margin-bottom: 15px
    }

    .error-area h2 {
        font-size: 26px;
        margin-bottom: 15px
    }

    .error-area p {
        margin-bottom: 20px
    }

    .contacts-form {
        padding: 20px
    }

    .contacts-form h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .contacts-form .form-group {
        margin-bottom: 20px
    }

    .contact-and-address h2 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .contact-and-address .contact-and-address-content {
        margin-bottom: 20px;
        padding-bottom: 0
    }

    .contact-and-address .contact-and-address-content .contact-card {
        padding: 20px
    }

    .contact-and-address .contact-and-address-content .contact-card h4 {
        margin-bottom: 12px
    }

    .contact-and-address .contact-and-address-content .contact-card p {
        margin-bottom: 8px
    }

    .contact-and-address .social-media h3 {
        font-size: 22px;
        margin-bottom: 10px
    }

    .contact-and-address .social-media p {
        margin-bottom: 20px
    }

    .go-top {
        height: 40px;
        width: 40px;
        line-height: 40px
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .pl-20 {
        padding-left: 0
    }

    .pr-20 {
        padding-right: 0
    }

    .read-more-btn {
        font-size: 15px
    }

    .default-btn {
        font-size: 14px;
        padding: 10px 20px
    }

    .header-right-content .list ul li {
        margin-right: 17px
    }

    .banner-area .container-fluid {
        margin: auto;
        padding-left: 0
    }

    .slider-item {
        padding-top: 150px;
        padding-bottom: 150px;
        padding-left: 40px
    }

    .container-fluid {
        max-width: 960px !important
    }

    .slider-content h1 {
        font-size: 60px
    }

    .hero-slider.owl-theme .owl-dots {
        display: none
    }

    .hero-slider2.owl-theme .owl-nav [class*=owl-] {
        left: 50px
    }

    .hero-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 50px;
        left: auto
    }

    .banner-area.banner-area-five .slider-item {
        padding-left: 30px
    }

    .single-campus-card .campus-content {
        padding: 20px
    }

    .single-campus-card .campus-content h3 {
        font-size: 19px
    }

    .campus-content .counter .counter-card h1 {
        font-size: 30px
    }

    .campus-content .counter .counter-card h1 .target.heading-color {
        font-size: 30px
    }

    .campus-content .counter .counter-card p {
        font-size: 13px
    }

    .campus-content.style-2 {
        padding: 0;
        box-shadow: none;
        margin-left: 0
    }

    .campus-slider2 {
        margin-bottom: -20px
    }

    .campus-slider2.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .campus-slider2.owl-theme .owl-dots {
        display: none
    }

    .campus-slider {
        margin-bottom: -20px
    }

    .campus-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .campus-slider.owl-theme .owl-dots {
        display: none
    }

    .page-banner-area {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .single-courses-card .courses-content {
        padding: 20px
    }

    .single-courses-card .courses-content h3 {
        font-size: 20px
    }

    .single-courses-card .courses-content .admin-profile {
        padding-left: 75px
    }

    .courses-slider.mb-20 {
        margin-bottom: -20px
    }

    .courses-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .courses-slider.owl-theme .owl-dots {
        display: none
    }

    .single-academics-card2 {
        height: 400px
    }

    .single-academics-card3 {
        padding: 20px
    }

    .single-academics-card3 h3 {
        margin-bottom: 12px
    }

    .single-academics-card3 p {
        margin-bottom: 12px
    }

    .serch-content {
        padding: 20px
    }

    .serch-content h3 {
        font-size: 20px;
        margin-bottom: 13px
    }

    .serch-content .form-group .src-btn {
        top: 10px
    }

    .category-content {
        padding: 20px
    }

    .category-content h3 {
        font-size: 20px;
        margin-bottom: 13px
    }

    .location-type {
        padding-bottom: 20px;
        margin-bottom: 20px
    }

    .location-type h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .program-level h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .academics-details .details h2 {
        font-size: 28px;
        margin-bottom: 18px
    }

    .academics-details .details p {
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee {
        padding-bottom: 20px;
        margin-bottom: 20px
    }

    .academics-details .regiStartion-and-fee h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee p {
        margin-bottom: 15px
    }

    .academics-details .regiStartion-and-fee .list ul li {
        margin-bottom: 8px
    }

    .academics-details .prepare-exam h3 {
        font-size: 22px;
        margin-bottom: 15px
    }

    .academics-details .prepare-exam p {
        margin-bottom: 15px
    }

    .academics-details .prepare-exam .exam-card {
        padding-left: 20px;
        margin-bottom: 20px
    }

    .academics-details .prepare-exam .exam-card h4 {
        font-size: 18px;
        margin-bottom: 10px
    }

    .academics-details .prepare-exam .exam-card h4::before {
        left: -20px
    }

    .academics-list {
        padding: 20px
    }

    .academics-list h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .related-download {
        padding: 14px
    }

    .related-download h3 {
        font-size: 20px
    }

    .related-download ul li {
        padding: 17px;
        padding-left: 70px
    }

    .single-events-card .events-image .date {
        left: 20px;
        padding: 10px 20px
    }

    .single-events-card .events-content {
        padding: 20px
    }

    .single-events-card .events-content .admin {
        margin-bottom: 15px
    }

    .single-events-card .events-content h3 {
        font-size: 20px
    }

    .single-events-card.style2 .events-content .admin-and-date ul li p {
        font-size: 13px
    }

    .events-slider.mb-20 {
        margin-bottom: -20px
    }

    .events-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .events-slider.owl-theme .owl-dots {
        display: none
    }

    .events-details-left-content .events-image {
        margin-bottom: 25px
    }

    .events-details-left-content .about-lecturer h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .events-details-left-content .about-lecturer p {
        margin-bottom: 20px
    }

    .events-details-left-content .about-lecturer .address {
        margin-bottom: 20px
    }

    .events-details-left-content .about-lecturer .address h4 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .meetings {
        padding-bottom: 10px;
        margin-bottom: 20px
    }

    .meetings h2 {
        font-size: 28px;
        margin-bottom: 15px
    }

    .meetings p {
        margin-bottom: 15px
    }

    .next-workshop {
        padding-bottom: 20px;
        margin-bottom: 20px
    }

    .next-workshop h3 {
        font-size: 20px
    }

    .next-workshop .list ul li {
        margin-bottom: 8px;
        padding-left: 110px
    }

    .brought h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .brought p {
        margin-bottom: 15px
    }

    .single-education-card {
        padding: 40px 15px
    }

    .single-education-card .edication-content h3 {
        font-size: 17px
    }

    .single-education-card .edication-content .read-more-btn {
        font-size: 14px
    }

    .single-health-care-card .health-care-content {
        padding: 20px
    }

    .single-health-care-card .health-care-content h3 {
        font-size: 20px
    }

    .health-care-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .more-health-care.style2 {
        padding-right: 36px
    }

    .health-details .top-content h2 {
        font-size: 30px
    }

    .tranding {
        padding: 20px
    }

    .how-to-apply h2 {
        font-size: 30px;
        margin-bottom: 15px
    }

    .how-to-apply p {
        margin-bottom: 15px
    }

    .submit h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .financial h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .additional-information {
        padding: 20px
    }

    .additional-information .list ul li {
        padding: 12px
    }

    .single-program-card {
        padding: 20px
    }

    .single-program-card h3 {
        margin-bottom: 15px
    }

    .single-podcasts-card .podcast-content h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .single-podcasts-card .podcast-content p {
        margin-bottom: 15px;
        font-size: 13px
    }

    .single-podcasts-card.style3 .podcast-content {
        background-color: #fff;
        padding: 20px
    }

    .single-podcasts-card.style3 .podcast-content .date p {
        margin-bottom: 0
    }

    .single-stories-card .stories-content h3 {
        font-size: 20px
    }

    .single-news-card .news-content {
        padding: 20px
    }

    .single-news-card .news-content .list ul li {
        margin-right: 10px
    }

    .single-news-card .news-content .list ul li:last-child {
        margin-right: 0
    }

    .single-news-card .news-content h3 {
        font-size: 18px
    }

    .latest-news-simple-card {
        height: 350px
    }

    .latest-news-simple-card .news-content {
        padding: 20px
    }

    .latest-news-simple-card .news-content h3 {
        font-size: 26px
    }

    .latest-news-card-area h3 {
        font-size: 20px
    }

    .category-list {
        padding: 20px
    }

    .category-list h3 {
        font-size: 20px;
        margin-bottom: 15px
    }

    .related-post-area {
        padding: 20px
    }

    .related-post-area h3 {
        font-size: 20px
    }

    .related-post-area .related-post-box .related-post-content h4 {
        font-size: 15px
    }

    .news-details .news-simple-card .list {
        margin-bottom: 10px
    }

    .news-details .news-simple-card h2 {
        font-size: 30px;
        margin-bottom: 20px;
        margin-bottom: 15px
    }

    .news-details .news-simple-card p {
        margin-bottom: 15px
    }

    .news-details .theory .theory-box {
        padding-left: 20px;
        margin-bottom: 20px
    }

    .news-details .theory .theory-box h4::before {
        left: -20px
    }

    .news-details .blog-img-and-content {
        margin-bottom: 15px
    }

    .comments {
        padding-bottom: 0;
        margin-bottom: 20px
    }

    .comments h3 {
        font-size: 22px
    }

    .comments h4 {
        margin-bottom: 8px;
        font-size: 18px
    }

    .comments .date {
        margin-bottom: 15px
    }

    .reply-area h3 {
        font-size: 22px
    }

    .reply-area .reply-form .form-group {
        margin-bottom: 20px
    }

    .news-slider {
        margin-bottom: -20px
    }

    .news-slider.owl-theme .owl-nav [class*=owl-] {
        position: unset;
        margin-top: 20px;
        margin-bottom: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px
    }

    .news-slider.owl-theme .owl-dots {
        display: none
    }

    .paginations ul li a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 16px
    }

    .alumni-left-content h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .single-alumni-events-card {
        padding: 0
    }

    .single-alumni-events-card .alumni-content h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .alumni-right-content h3 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .single-alumoni-updates-card {
        padding: 0
    }

    .single-alumoni-updates-card .updates-content h3 {
        font-size: 17px
    }

    .interview-content {
        position: relative
    }

    .interview-content::before {
        position: absolute;
        content: "";
        background-color: rgba(31, 44, 117, .7);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0
    }

    .interview-content .interview {
        position: relative;
        padding-left: 15px;
        padding-right: 15px
    }

    .interview-content .interview span {
        color: #e32845
    }

    .interview-content .interview h2 {
        color: #fff
    }

    .interview-content .interview p {
        color: #fff
    }

    .faq-title h2 {
        font-size: 28px;
        margin-bottom: 15px
    }

    .faq-title p {
        margin-bottom: 20px
    }

    .accordion .accordion-item .accordion-button {
        padding: 12px;
        font-size: 17px
    }

    .coming-soon-area .coming-soon .newsletter-form .default-btn {
        padding: 15px 25px
    }
}

@media only screen and (min-width:1200px) and (max-width:1399px) {
    .top-header-area .container-fluid {
        max-width: 1140px
    }

    .navbar-area .container-fluid {
        max-width: 1140px
    }

    .page-banner-area .container-fluid {
        max-width: 1140px
    }

    .banner-area.banner-area-five .slider-item {
        padding-left: 15px
    }

    .health-care-slider.owl-theme .owl-nav [class*=owl-] {
        left: -25px
    }

    .health-care-slider.owl-theme .owl-nav [class*=owl-].owl-next {
        right: -25px
    }

    .courses-slider.owl-theme .owl-nav [class*=owl-] {
        left: -25px
    }

    .courses-slider.owl-theme .owl-nav [class*=owl-].owl-next {
        right: -25px
    }

    .campus-slider.owl-theme .owl-nav [class*=owl-] {
        left: -25px
    }

    .campus-slider.owl-theme .owl-nav [class*=owl-].owl-next {
        right: -25px
    }

    .campus-slider2.owl-theme .owl-nav [class*=owl-] {
        left: -25px
    }

    .campus-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
        right: -25px
    }

    .events-slider.owl-theme .owl-nav [class*=owl-] {
        left: -25px
    }

    .events-slider.owl-theme .owl-nav [class*=owl-].owl-next {
        right: -25px
    }

    .news-slider.owl-theme .owl-nav [class*=owl-] {
        left: -25px
    }

    .news-slider.owl-theme .owl-nav [class*=owl-].owl-next {
        right: -25px
    }

    .default-btn {
        font-size: 15px;
        padding: 12px 25px
    }

    .more-health-care.style2 {
        padding-right: 36px
    }

    .single-academics-card .academic-top-content h3 {
        font-size: 18px
    }

    .single-alumni-events-card {
        padding: 0
    }

    .single-alumni-events-card .alumni-content h3 {
        font-size: 20px;
        margin-bottom: 10px
    }

    .single-alumoni-updates-card {
        padding: 0
    }

    .single-alumoni-updates-card .updates-content h3 {
        font-size: 20px
    }

    .single-campus-card .campus-content h3 {
        font-size: 20px
    }

    .single-health-care-card .health-care-content h3 {
        font-size: 20px
    }

    .single-events-card .events-content h3 {
        font-size: 20px
    }

    .single-news-card .news-content h3 {
        font-size: 20px
    }

    .coming-soon-area .coming-soon .newsletter-form .default-btn {
        padding: 14px 25px
    }
}

@media only screen and (max-width:991px) {
    .navbar-area {
        background-color: #fff;
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
        z-index: 2;
        padding-top: 20px;
        padding-bottom: 20px
    }

    .navbar-area .dropdown-toggle::after {
        display: none
    }

    .navbar-area.navbar-top {
        transform: translateY(0)
    }

    .navbar-area.nav-bg-3 {
        border-bottom: none
    }

    .navbar-area.nav-bg-3.is-sticky {
        background: #fff;
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05)
    }

    .mobile-responsive-nav {
        display: block;
        z-index: 9999
    }

    .mobile-responsive-nav .mobile-responsive-menu {
        position: relative
    }

    .mobile-responsive-nav .mobile-responsive-menu .logo {
        position: relative;
        z-index: 2;
        max-width: 185px;
        display: block
    }

    .mobile-responsive-nav .mobile-responsive-menu .logo .white-logo {
        display: none
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav {
        margin-top: 54px;
        background-color: #fff
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul {
        border: none !important;
        background-color: #fff
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a {
        border-top-color: #efefef;
        color: #111 !important;
        font-size: 14px !important;
        font-weight: 600;
        text-transform: capitalize
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a i {
        display: none
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a.mean-expand {
        width: 100%;
        height: 28px;
        text-align: right;
        padding: 11px !important;
        background: 0 0 !important;
        border-left: none !important;
        border-bottom: none !important
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a.active {
        color: #e32845 !important
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li li a {
        font-size: 15px
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav {
        box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
        margin-top: 0 !important;
        max-height: 50vh;
        overflow-y: scroll
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar {
        width: 7px
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-track {
        background: #f1f1f1
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-thumb {
        background: #888
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-thumb:hover {
        background: #555
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container a.meanmenu-reveal {
        top: 11px;
        padding: 0;
        width: 35px;
        height: 30px;
        padding-top: 5px;
        color: #e32845;
        display: block !important;
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container a.meanmenu-reveal span {
        background: #e32845;
        height: 4px;
        margin-top: -6px;
        border-radius: 0;
        position: relative;
        top: 8px;
        display: block !important;
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-bar {
        background: 0 0;
        position: absolute;
        z-index: 2;
        padding: 0
    }

    .mobile-responsive-nav .others-options {
        display: none !important
    }

    .desktop-nav {
        display: none !important
    }

    .others-option-for-responsive {
        display: block !important
    }
}


/*!#######################################################################
MeanMenu 2.0.7
--------
To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)
#######################################################################*/
a.meanmenu-reveal {
    display: none
}

.mean-container .mean-bar {
    float: left;
    width: 100%;
    position: relative;
    background: #0c1923;
    padding: 4px 0;
    min-height: 42px;
    z-index: 999999;
}

.mean-container a.meanmenu-reveal {
    width: 22px;
    height: 22px;
    padding: 13px 13px 11px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    text-indent: -9999em;
    line-height: 22px;
    font-size: 1px;
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700
}

.mean-container a.meanmenu-reveal span {
    display: block;
    background: #fff;
    height: 3px;
    margin-top: 3px
}

.mean-container .mean-nav {
    float: left;
    width: 100%;
    background: #0c1923;
    margin-top: 44px
}

.mean-container .mean-nav ul {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style-type: none
}

.mean-container .mean-nav ul li {
    position: relative;
    float: left;
    width: 100%
}

.mean-container .mean-nav ul li a {
    display: block;
    float: left;
    width: 90%;
    padding: 1em 5%;
    margin: 0;
    text-align: left;
    color: #fff;
    border-top: 1px solid #383838;
    border-top: 1px solid rgba(255, 255, 255, .5);
    text-decoration: none;
    text-transform: uppercase
}

.mean-container .mean-nav ul li li a {
    width: 80%;
    padding: 1em 10%;
    border-top: 1px solid #f1f1f1;
    border-top: 1px solid rgba(255, 255, 255, .25);
    opacity: .75;
    filter: alpha(opacity=75);
    text-shadow: none !important;
    visibility: visible
}

.mean-container .mean-nav ul li.mean-last a {
    border-bottom: 0;
    margin-bottom: 0
}

.mean-container .mean-nav ul li li li a {
    width: 70%;
    padding: 1em 15%
}

.mean-container .mean-nav ul li li li li a {
    width: 60%;
    padding: 1em 20%
}

.mean-container .mean-nav ul li li li li li a {
    width: 50%;
    padding: 1em 25%
}

.mean-container .mean-nav ul li a:hover {
    background: #252525;
    background: rgba(255, 255, 255, .1)
}

.mean-container .mean-nav ul li a.mean-expand {
    margin-top: 1px;
    width: 26px;
    height: 32px;
    padding: 12px !important;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    font-weight: 700;
    background: rgba(255, 255, 255, .1);
    border: 0 !important;
    border-left: 1px solid rgba(255, 255, 255, .4) !important;
    border-bottom: 1px solid rgba(255, 255, 255, .2) !important
}

.mean-container .mean-nav ul li a.mean-expand:hover {
    background: rgba(0, 0, 0, .9)
}

.mean-container .mean-push {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    clear: both
}

.mean-nav .wrapper {
    width: 100%;
    padding: 0;
    margin: 0
}

.mean-container .mean-bar,
.mean-container .mean-bar * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.mean-remove {
    display: none !important
}



@media(min-width:1200px) {
    .secondNavbar .navbar-nav .nav-item a:before {
        color: #fff !important;
        content: "|";
        float: right;
        margin-left: 10px;
    }
    .afterarrow:after {
        display: none;
        color: #fff !important;
        content: "|";
        float: right;
        margin-left: 10px;
        position: absolute;
        right: 0;
        bottom: 14px;
        height: 23px;
        font-size: 14px;
    }

    .secondNavbar .navbar-nav .nav-item:last-child a:before {
        display: none;
        content: '';
    }
}

@media(min-width:992px) {
    .banner-bg-1 {
        height: 80vh;
    }

    .banner-bg-2 {
        height: 80vh;
    }

    .banner-bg-3 {
        height: 80vh;
    }

    .aboutClg-Section .hero-slider2.owl-theme .owl-nav [class*=owl-] {
        left: 43px;
    }

    .aboutClg-Section .hero-slider2.owl-theme .owl-nav [class*=owl-].owl-next {
        left: auto;
        right: 43px;
    }

    .slider-content {

        padding-top: 100px !important;
    }

    .how-to-apply p {
        margin-bottom: 15px;
        text-align: justify;
    }



}



.how-to-apply table,
th,
td {
    border: solid 1px #ddd;
    padding: 11px 40px;
}

.how-to-apply th {
    background: #cdcfd2;
}

.how-to-apply table {
    border-collapse: collapse;
    caption-side: bottom;
}

caption {
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
}



.managementText p {
    color: #bbb;
    text-align: center;
    margin-top: -3px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li a {
    color: #1b2336 !important;
    padding: 13px;
    border-bottom: 1px dashed #ededed;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    display: block;
    position: relative;
    line-height: 20px;
    width: 250px;
    font-size: 14px;
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}

.table-bordered>:not(caption)>*>* {
    border-width: 1px solid #ddd;
}

@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
        white-space: nowrap;
    }

    .how-to-apply table {
        white-space: nowrap;
    }

    .news-heading {
        background: #f81300;
        color: #fff;
        text-align: center;
        padding: 4px 1px;
        margin-bottom: 0;
        position: relative;
        font-size: 12px;

    }
}

@media screen and (max-width: 991.9px) {
    .banner-bg-1 {
        height: 90vh;
    }

    .banner-bg-2 {
        height: 90vh;
    }

    .banner-bg-3 {
        height: 90vh;
    }

    .wed-foot-link div {
        border-right: 0px !important;
        padding: 12px;
    }
}

@media screen and (max-width: 565px) {
    .d-none{
        display:none;
    }
    .footerInput {
        width: 95%;
    }
    .secondNavbar {
        display: none !important;
    }

    .banner-bg-1, .banner-bg-2, .banner-bg-3 {
        height: 23vh;
        background-size: cover;
        width: 100%;
    }

   
}

.gallery-container a {
    width: 240px;
    margin: 5px;
}

.gallery-container a img {
    max-width: 100%;
    height: auto;
}

.hero-slider2 .nav {
    display: none;
}

.hero-slider2 .dots {
    display: block;
}

.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: #ffffff;
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.how-to-apply .table th {
    background: #edf0f6;
    color: #000;
}

.how-to-apply th {
    background: #edf0f6;
    color: #000;
}

.navbar-toggler {
    border: 0;
}

.navbar-toggler span {
    color: red;
}

/* .mobileNavbar .navbar-nav .dropdown-menu {
    position: static;
    height: 300px;
    overflow-y: auto;
    width: 250px;
    overflow-x: hidden;
} */
.meanmenu-reveal {
    right: 0px;
    left: auto;
    text-align: center;
    text-indent: 0px;
    font-size: 18px;
}

.events-slider .owl-carousel .owl-dots.disabled {
    display: block;
}

.nav-link {
    font-size: 13px;
    font-weight: 600;
}

.navbar-toggler i {
    width: 24px;
    height: 43px;
    padding-top: 8px;
    color: #e32845;
    font-size: 27px;
}

.hero-slider2.owl-theme .owl-nav [class*=owl-] {
    color: red;
}

.SubramanyamText p {
    margin-top: 0px !important;
    margin-bottom: 1px !important;
    color: #000;
}

.SubramanyamText a {
    color: #555;
}


.pbottom15 {
    padding-bottom: 15px;
}

.b-all {
    border: 1px solid #f1f1f1;
    padding: 10px;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.related-download ul li a:hover {
    color: red;
    text-decoration: underline;
}

.bg-gray {
    background-color: #3c84a72b !important;
}


















@media only screen and (max-width: 767px) {
    .aboutClgSection.owl-theme .owl-nav [class*=owl-] {
        position: absolute;
        left: 0;
        height: 35px;
        width: 35px;
        line-height: 37px;
        font-size: 18px;
        right: 71px;
        bottom: -375px;
        margin: auto;
        opacity: 1;
    }
}

.gallery-area {
    padding-top: 100px;
    padding-bottom: 70px;
    background-color: #f5f5f5;
}

.single-image {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
}

.single-image img {
    max-width: 100%;
}

.single-image::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: .4s;
    transition: .4s;
}

.single-image a {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    margin: 0 auto;
    font-size: 30px;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    margin-top: -20px;
    -webkit-transition: .4s;
    transition: .4s;
    -webkit-transform: scale(0);
    transform: scale(0);
    font-size: 20px;
}

.single-image a:hover {
    color: red;
    text-decoration: none;
}

.single-image:hover::before {
    visibility: visible;
    opacity: .8;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.single-image:hover a {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.academicsText {
    background-color: #03294e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.academicsText h3 {
    color: #ffffff;
}

.table-responsive .related-download {

    background-color: #f4f6f9;
    margin-bottom: 0;
}

.table-responsive .related-download ul {
    padding-left: 0;
    margin-bottom: 0;
    background-color: #ffff;
}



.hero-slider.owl-theme .owl-dots .owl-dot.active span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    display: none !important;
}

.text-black {
    color: #555;
}

.text-000 {
    color: #000;
}

.cseSideNavabar li link {
    color: #000;
}

/* .table-responsive{
    
} */
.additional-information .list ul li I {
    color: #764AF1;
    margin-right: 5px;
}

.additional-information .list ul li:hover I {
    color: #ffff;
}

.how-to-apply td p {
    text-align: start;
}



.m-p-g {
    max-width: 100%;
    margin: 0 auto;
}

.m-p-g__thumbs-img {
    margin: 0;
    float: left;
    vertical-align: bottom;
    cursor: pointer;
    z-index: 1;
    position: relative;
    opacity: 0;
    filter: brightness(100%);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: opacity, transform;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.m-p-g__thumbs-img.active {
    z-index: 50;
}

.m-p-g__thumbs-img.layout-completed {
    opacity: 1;
}

.m-p-g__thumbs-img.hide {
    opacity: 0;
}

.m-p-g__thumbs-img:hover {
    filter: brightness(110%);
}

.m-p-g__fullscreen {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
    visibility: hidden;
    transition: background 0.25s ease-out, visibility 0.01s 0.5s linear;
    will-change: background, visibility;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.m-p-g__fullscreen.active {
    transition: background 0.25s ease-out, visibility 0.01s 0s linear;
    visibility: visible;
    background: rgba(0, 0, 0, 0.95);
}

.m-p-g__fullscreen-img {
    pointer-events: none;
    position: absolute;
    transform-origin: left top;
    top: 50%;
    left: 50%;
    max-height: 100vh;
    max-width: 100%;
    visibility: hidden;
    will-change: visibility;
    transition: opacity 0.5s ease-out;
}

.m-p-g__fullscreen-img.active {
    visibility: visible;
    opacity: 1 !important;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.5s ease-out;
}

.m-p-g__fullscreen-img.almost-active {
    opacity: 0;
    transform: translate3d(0, 0, 0) !important;
}

.m-p-g__controls {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    height: 20vh;
    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.55) 100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.m-p-g__controls.active {
    opacity: 1;
    visibility: visible;
}

.m-p-g__controls-close,
.m-p-g__controls-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: none;
}

.m-p-g__controls-close:focus,
.m-p-g__controls-arrow:focus {
    outline: none;
}

.m-p-g__controls-arrow {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 20%;
    height: 100vh;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    opacity: 0;
}

.m-p-g__controls-arrow:hover {
    opacity: 1;
}

.m-p-g__controls-arrow--prev {
    left: 0;
    padding-left: 3vw;
    justify-content: flex-start;
}

.m-p-g__controls-arrow--next {
    right: 0;
    padding-right: 3vw;
    justify-content: flex-end;
}

.m-p-g__controls-close {
    position: absolute;
    top: 3vh;
    left: 3vw;
    z-index: 5;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.m-p-g__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.07);
    transition: all 0.25s ease-out;
}

.m-p-g__btn:hover {
    background: rgba(255, 255, 255, 0.15);
}

.m-p-g__alertBox {
    position: fixed;
    z-index: 999;
    max-width: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 25px;
    border-radius: 3px;
    text-align: center;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
    color: grey;
}

.m-p-g__alertBox h2 {
    color: red;
}


.demo-btn {
    display: inline-block;
    margin: 0 2.5px 4vh 2.5px;
    text-decoration: none;
    color: grey;
    padding: 15px;
    line-height: 1;
    min-width: 140px;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 6px;
}

.demo-btn:hover {
    background: rgba(0, 0, 0, 0.12);
}

.crg-td-list ul li:hover .crg-info .crg-view ul li a.crg-link,
.crg-td-list2 ul li.active .crg-info .crg-view ul li a.crg-link2 {
    border: 0px;
    color: #ffffff;
    background: linear-gradient(to right, #f813008f 50%, #f81300 50%) right bottom / 200% 100% repeat scroll transparent !important;
    text-align: center;
    transition: background 350ms ease-in-out;
}

.impx-testimonial-item:before,
.impx-testimonial-item:after {
    content: "\f10d";
    position: absolute;
    left: 20px;
    top: 10px;
    color: rgba(0, 0, 0, 0.08);
    font-family: FontAwesome;
    font-size: 4rem;
    z-index: -1;
}

.impx-testimonial-item {
    position: relative;
    padding: 20px 25px 32px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}

.border-xlight {
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
}

.impx-testi-text blockquote {
    padding: 20px;
    position: relative;
    z-index: 2;
    margin: 0 0;
    border-left: 0;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075) !important;
}

.impx-testi-image {
    position: absolute;
    top: -42px;
    right: -14px;
    z-index: 2;
}

.impx-testi-image img {
    height: 80px;
}

.impx-testi-image img {
    display: inline;
    background-color: #b8b8b8;
    padding: 6px;

}

.impx-testi-name {
    padding: 0 20px;
}

.impx-testimonial-item:after {
    content: "\f10e";
    right: 30px;
    bottom: 0px;
    left: auto;
    top: auto;
}

@media (max-width: 640px) {
    .demo-btn {
        min-width: 0;
        font-size: 14px;
    }
    .single-education-card{
       
            margin: 0 13px 30px;
            
        
    }
}









.popup-gallery img {
    width: 100%;
    padding-bottom: 25px;
}














.magnific-img img {
    width: 100%;
    height: auto;
}

.magnific-img {
    display: inline-block;
    width: 32.3%;
}

a.image-popup-vertical-fit {
    cursor: -webkit-zoom-in;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;

    /* ideally, transition speed should match zoom duration */
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.98;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-arrow-left:before {
    border-right: none !important;
}

.mfp-arrow-right:before {
    border-left: none !important;
}

button.mfp-arrow,
.mfp-counter {
    opacity: 0 !important;
    transition: opacity 200ms ease-in, opacity 2000ms ease-out;
}

.mfp-container:hover button.mfp-arrow,
.mfp-container:hover .mfp-counter {
    opacity: 1 !important;
}


/* Magnific Popup CSS */
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;

}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #CCC;
}

.mfp-preloader a:hover {
    color: #FFF;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;

    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.mfp-arrow-left {
    left: 0;
}

.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

/* Main image in popup */
img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-figure figure {
    margin: 0;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

    /**
* Remove all paddings around the image on small screen
*/
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box;
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.text-blue-tag {
    color: #0077b5 !important;
    text-decoration: underline;
}

.how-to-apply td p {
    text-align: start;
    font-size: 13px;
}

.faculty-S-Image {
    height: 100px !important;
    width: 80px !important;
    max-width: 80px !important;
}

.text-bold {
    min-width: 130px;
}

/* .additional-information {
    background-color: #f4f6f9;
    padding: 30px;
    margin-bottom: 30px
} */
.additional-information {
    background-color: #F2F2F2;
    padding: 14px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.additional-information .list ul li {
    list-style-type: none;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    transition: all ease .5s;
    text-align: start;
    border-radius: 5px;
}

.footerBtn:hover {
    border: 0px;
    color: #ffffff;
    background: linear-gradient(to right, #f813008f 50%, #6f24c9 50%) right bottom / 200% 100% repeat scroll transparent !important;
    text-align: center;
    transition: background 350ms ease-in-out;
}

.footerBtn {
    background: #9772FB;
}

/* .additional-information .list ul li:active {
    background-color: blueviolet;
    color: whitesmoke;
    font-weight: bold;
}.additional-information .list ul li.active {
    background-color: blueviolet;
    color: whitesmoke;
    font-weight: bold;
    } */


/* Style the active class, and buttons on mouse-over */
.socialMediaIcons li:hover i {
    background-color: #f81300;
}

.logo img {
    height: 75px;
}

.flaticon-pdf-file::before {
    content: "\f1c1" !important;
    font-family: 'FontAwesome' !important;
    line-height: 1;
    font-size: 17px;
    font-style: normal;
}

.owl-next:before {
    content: "\f061";
    font-family: 'FontAwesome' !important;
}

.owl-prev:after {
    content: "\f060";
    font-family: 'FontAwesome' !important;
}

.accordion .accordion-item {
    margin-bottom: 20px;
    border: 1px solid #eee;
    border-radius: 10px;
}

.indiaoOrgText:hover {
    text-decoration: underline;
}

.socialMediaIcons ul li {
    width: 10% !important;

    line-height: 32px;
    font-weight: 500;
}

.ri-checkbox-circle-line:before {
    content: "\eb81";

}
.main-logo{
    width: 280px;
    padding: 10px 0;
}
.additional-information .list > ul .active {
    font-weight: bold;
    color:#fff !important;
 background-color: #ff0000 !important;
  }
  .additional-information .list > ul .active .fa, 
  .additional-information .list > ul .active a{
    color:#fff !important;
  }
  .wed-hom-footer {
    padding: 40px 0 10px;
}
.wed-hom-footer ul li{
    list-style: none;
}
.wed-hom-footer ul li a{
    color: #fff;
    line-height: 28px;
    font-size: 13px;
}
.head-tab{
    margin: 0 0 10px 0;
}
.head-tab2 h5{
    bottom:18px !important;
}
.head-tab h5{
    position: relative;
    bottom:35px;
    background: #1E2A5C;
    color:#ffffff;
    display: inline;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 50px;
    border-radius: 5px;
    font-size: 12px;
    
}
.simage{
    height: 130px;
    border-radius: 10px;
}
.grade-wrapper{
    background: #C71B27;
    padding: 6px 0;
}
.grade-wrapper p{
    color:#ffffff;
    font-size:11px;
}
.grade-wrapper h6{
    font-size:14px;
    margin-bottom: 0;
}
.marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 250px;
    overflow-x: hidden;
  }
  
  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 15s linear infinite;
  }
  .track .content{
    display: flex;
    align-items: center;
  }
  .track .content .scroll-c{
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #bcb5b5;
    border-radius: 8px;
    width: 169px;
    background: #fff;
  }
  .track .content .scroll-c img{
    width: 125px;
    padding: 29px 0;
  }
  
  @keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }
  .scroll{
    display: flex;
    align-items: center;
  }
  .scroll-c{
    padding: 10%;
    
  }
  .placements-offer-wrapper{
    background: #fde8e9;
    text-align: center;
    padding: 10px;
    border-top-right-radius: 55px;
    border-bottom-right-radius: 55px;
  }
  .placements-offer-wrapper h6{
    border-bottom: 1px solid #000000;
    font-size: 20px;
  }
  .placement-list-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .placement-list-wrapper .number{
    /* border: 1px solid #000; */
    border-radius: 50%;
    padding: 10px 12px;
    position: relative;
    left: 10px;
    background: #1E2A5C;
    margin-bottom: 8px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 800;
    line-height: 18px;
  }
  .placement-list-wrapper .ofer{
    background: #eeebf2;
    border: 1px solid #000;
    padding: 17px 5px 19px 11px;
    font-size: 11px;
    min-width: 115px;
    text-align: center;
    min-height: 55px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bg-light-blue{
    background: #E5F2FA;
  }
  .bg-color{background: #f7f7f7;}
  @media screen and (max-width:567px){
    .grade-wrapper {
        background: #C71B27;
        padding: 6px 0;
        width: 166px;
        margin: 0 auto 31px;
    }
    .head-tab h5{
    display: block;
    }
    .placements-offer-wrapper{
        margin-bottom: 20px;
    }
    .placement-list-wrapper{
        margin: 0 auto ;
        width: 80%;
    display: block;
    
    }
    .placement-list-wrapper .number{
        top: 11px;
        left: 0;
        margin:0 auto;
    }
    .bg-color {
        width: 100%;
        margin: 0px;
        padding: 0 5px;
    }
  }
  .btn-danger{
    background:red !important;
  }
  
  .btn-danger:hover{
    background:#dc3545 !important;
  }
  .additional-information .list ul li{
    color: black;
  }

  .comapany-max-height{
    max-height: 45px !important;
  }